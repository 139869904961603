<template>
  <div class="container">
    <!-- 面包屑导航区 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>USDT追踪</el-breadcrumb-item>
      <el-breadcrumb-item>地址</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="content">
      <el-card>
        <el-row type="flex" class="search-box" justify="space-between">
          <el-col :span="4">
            <el-select v-model="virtualAddress" clearable placeholder="请选择地区">
              <el-option v-for="item in virtualAddressList" :key="item.value" :label="item.name" :value="item.value"> </el-option>
            </el-select>
          </el-col>
          <el-col :span="4">
            <el-select v-model="customerType" clearable placeholder="请选择客户类型">
              <el-option v-for="item in customerTypeList" :key="item.value" :label="item.name" :value="item.value"> </el-option>
            </el-select>
          </el-col>
          <el-col :span="2"><el-button type="warning" @click="getTrackingGroupPageList">搜索</el-button> </el-col>
          <el-col :span="2"><el-button type="warning">自己</el-button> </el-col>
          <el-col :span="2" v-show="editFinishShow == false"><el-button type="warning" @click="editFinishShow = true">编辑</el-button> </el-col>
          <el-col :span="2" v-show="editFinishShow == true"><el-button type="warning" @click="editFinishShow = false">完成</el-button> </el-col>
          <el-col :span="3"><el-button type="primary" @click="addAddressTracking">添加地址追踪</el-button> </el-col>
          <el-col :span="5"> 参考价: </el-col>
          <el-col :span="5">
            <span>
              余额总计:
              <span style="color: red; font-size: 28px; font-weight: bold">{{ result.integerPart }} </span>
              <span style="color: red; font-size: 22px; font-weight: bold"> .{{ result.decimalPart }} </span>
            </span>
          </el-col>
        </el-row>
      </el-card>
      <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="currentPage" :page-sizes="[100, 200, 300, 400, 500]" :page-size="100" layout="total, sizes, prev, pager, next, jumper" :total="page.total"> </el-pagination>
      <template>
        <div class="main-container">
          <div v-for="(item, index) in tableData" :key="index" class="box">
            <div class="title">
              <i v-show="editFinishShow" class="el-icon-circle-close" @click="deleteAddress(item)"></i>
              <div class="name-box">
                <div class="name-item" @click="jumpToUserPage(item.archivesNo)">
                  <span class="name-label">姓名:</span><span class="name-value">{{ item.items[0].archivesName }}({{ item.archivesNo }})-{{ virtualAddressDefinition(item.items[0].area) }}-{{ customerTypeDefinition(item.items[0].type) }}</span>
                </div>
                <div class="count">
                  <span class="count-label">余额</span>
                  <span class="count-value">{{ item.account.integerPart }}</span>
                  <span class="count-decimal">.{{ item.account.decimalPart }}</span>
                </div>
              </div>
            </div>
            <div class="content">
              <div v-for="(cItem, cIndex) in item.items" :key="cIndex" class="address-box">
                <div @click="handleClick(cItem)" class="address-item">
                  <span class="address-label">地址:</span><span class="address-value">{{ formatString(cItem.address) }}</span>
                </div>
                <div class="address-balance">
                  (<span class="balance-label"> {{ cItem.account.integerPart }}.</span>
                  <span class="balance-value">{{ cItem.account.decimalPart }}</span>
                  ) <i v-show="editFinishShow" style="font-size: 17px" @click="deleteTracking([cItem.id])" class="el-icon-error"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
      <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="currentPage" :page-sizes="[100, 200, 300, 400, 500]" :page-size="100" layout="total, sizes, prev, pager, next, jumper" :total="page.total"> </el-pagination>
      <el-dialog :title="addressTracking" :visible.sync="dialogAddress">
        <el-form :model="form">
          <el-form-item label="别名" label-width="120px">
            <el-input v-model="form.alisa" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="地址" label-width="120px">
            <el-input v-model="form.address" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="币种" label-width="120px">
            <el-select v-model="form.currency" placeholder="请选择币种" @change="handleCurrencyChange">
              <el-option :label="item.name" :value="item.code" v-for="item in currencyList" :key="item.id"> </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="链/协议" label-width="120px">
            <el-select v-model="form.chain" placeholder="请选择链/协议">
              <el-option :label="item.protocol" :value="item.protocol" v-for="item in chainList" :key="item.id"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="是否启用" label-width="120px">
            <el-switch v-model="form.isEnable" active-color="#13ce66" inactive-color="#ff4949"> </el-switch>
          </el-form-item>
          <el-form-item label="是否是内部地址" label-width="120px">
            <el-switch v-model="form.isInner" active-color="#13ce66" inactive-color="#ff4949"> </el-switch>
          </el-form-item>
        </el-form>
        <div slot="footer">
          <el-button @click="dialogAddress = false">取 消</el-button>
          <el-button type="primary" @click="handleAddAddressTracking">确 定</el-button>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  components: {},
  watch: {},
  props: {},
  data() {
    return {
      editFinishShow: false,
      customerType: "",
      virtualAddress: "",
      virtualAddressList: [
        {
          name: "香港",
          value: "HongKong",
        },
        {
          name: "澳门",
          value: "Macao",
        },
        {
          name: "新加坡",
          value: "Singapore",
        },
      ], //地区地址信息
      customerTypeList: [
        {
          name: "客人",
          value: "guest",
        },
        {
          name: "供应商",
          value: "supplier",
        },
        {
          name: "商家",
          value: "business",
        },
      ], //客人类型
      addressTracking: "添加地址追踪",
      dialogAddress: false,
      currentPage: 1,
      form: {
        alisa: "",
        currency: "",
        chain: "",
        address: "",
        isEnable: true,
        isInner: true,
      },
      currencyList: [],
      chainList: [],

      tableData: [],
      page: {
        index: 1,
        size: 100,
        total: 0,
      },
      result: [],
    };
  },
  onLoad() {},
  mounted() {
    this.getTrackingGroupPageList();
    this.getCurrencyList();
  },
  onShow() {},
  methods: {
    // 公共事件
    virtualAddressDefinition(value) {
      const virtualAddressList = [
        { name: "香港", value: "HongKong" },
        { name: "澳门", value: "Macao" },
        { name: "新加坡", value: "Singapore" },
      ];
      if (value === "") {
        return "";
      }
      const result = virtualAddressList.find((item) => item.value === value);
      return result ? result.name : "";
    },
    customerTypeDefinition(value) {
      const customerTypeList = [
        {
          name: "客人",
          value: "guest",
        },
        {
          name: "供应商",
          value: "supplier",
        },
        {
          name: "商家",
          value: "business",
        },
      ];
      if (value === "") {
        return "";
      }
      const result = customerTypeList.find((item) => item.value === value);
      return result ? result.name : "";
    },
    formatNumber(num) {
      // 将数字拆分为整数部分和小数部分，并保留小数点后两位
      const [integerPart, decimalPart] = num.toFixed(2).split(".");
      return {
        integerPart: integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ","), // 添加逗号
        decimalPart: decimalPart, // 直接使用小数点后的部分
      };
    },
    formatString(inputStr, maxLength = 10, ellipsis = "...") {
      if (inputStr.length <= maxLength) {
        return inputStr;
      } else {
        const halfLength = Math.floor((maxLength - ellipsis.length) / 2);
        const start = inputStr.slice(0, halfLength);
        const end = inputStr.slice(-halfLength);
        return start + ellipsis + end;
      }
    },
    // 跳转用户页面
    jumpToUserPage(data) {
      if (data == "") {
        return;
      }
      let queryString = `?no=${data}`;
      window.open(`/home/UserProfile${queryString}`, "_blank", "width=1920,height=1080");
    },
    // 删除接口 ---------------------------------------------------------------------------------
    async deleteAddress(item) {
      const ids = [];

      item.items.forEach((obj) => {
        if (obj.id !== undefined) {
          ids.push(obj.id);
        }
      });
      this.$confirm("此操作将永久删除该地址追踪, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        showCancelButton: true,
      })
        .then(() => {
          this.deleteTracking(ids);
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    async deleteTracking(ids) {
      try {
        let randomNum = Math.random();
        // 添加请求头部
        const headers = {
          "Content-Type": "application/json",
          "auth-id": "D1g4j7cXy578MvZbLQqWzdUeNG",
          "trace-id": randomNum,
        };
        // 发送 delete 请求
        const { data: res } = await axios.delete(
          "https://nxapi.seek940.com/wallet/api/v1/Tracking/BatchDelete",
          // "http://ttmarket.ttmarket.com.cn:28080/wallet/api/v1/Tracking/BatchDelete",
          { headers, data: ids }
        );
        // 检查响应数据是否存在
        if (res && res.data) {
          this.$message({
            type: "success",
            message: "删除成功!",
          });
          this.getTrackingGroupPageList();
        } else {
          console.error("Invalid res:", res);
        }
      } catch (error) {
        console.error("Error fetching USDT balance:", error);
      }
    },

    handleSizeChange(val) {
      this.page.size = val;
      this.getTrackingGroupPageList();
    },
    handleCurrentChange(val) {
      this.page.index = val;
      this.getTrackingGroupPageList();
    },
    handleClick(row) {
      localStorage.setItem("listData", JSON.stringify(row));
      this.$router.push({
        path: "/home/transactionHistory",
      });
    },
    addAddressTracking() {
      this.dialogAddress = true;
      this.addressTracking = "添加地址追踪";
      this.form = {
        id: "",
        alisa: "",
        currency: "",
        chain: "",
        address: "",
        isEnable: true,
      };
    },
    handleEdit(row) {
      this.dialogAddress = true;
      this.addressTracking = "编辑地址追踪";
      this.form = {
        id: row.id,
        alisa: row.alisa,
        currency: row.currency,
        chain: row.chain,
        address: row.address,
        isEnable: row.isEnable,
      };
    },
    // 添加地址追踪
    async handleAddAddressTracking() {
      try {
        // 生成一个16位的随机整数
        const min = Math.pow(2, 15); // 2^15
        const max = Math.pow(2, 16) - 1; // 2^16 - 1
        let randomNum = Math.floor(Math.random() * (max - min + 1)) + min;
        // 添加请求头部
        const headers = {
          "Content-Type": "application/json",
          "auth-id": "D1g4j7cXy578MvZbLQqWzdUeNG",
          "trace-id": randomNum,
          // 可以根据需要添加其他头部信息
        };
        // 发送 POST 请求
        const { data: res } = await axios.post(
          "https://nxapi.seek940.com/wallet/api/v1/Tracking/AddOrUpdateTracking",
          // "http://ttmarket.ttmarket.com.cn:28080/wallet/api/v1/Tracking/AddOrUpdateTracking",
          this.form,
          { headers }
        );

        // 检查响应数据是否存在
        if (res && res.code == 0) {
          // 处理响应数据
          this.dialogAddress = false;
          this.getTrackingGroupPageList();
        } else {
          console.error("Invalid res:", res);
        }
      } catch (error) {
        console.error("Error fetching USDT balance:", error);
      }
    },
    // 币种
    async handleCurrencyChange(val) {
      try {
        // 添加请求头部
        const headers = {
          "Content-Type": "application/json",
          "auth-id": "D1g4j7cXy578MvZbLQqWzdUeNG",
          // 可以根据需要添加其他头部信息
        };

        // 发送 POST 请求
        const { data: res } = await axios.get(
          "https://nxapi.seek940.com/foundation/api/Foundation/currenciyProtocol",
          // "http://ttmarket.ttmarket.com.cn:28080/foundation/api/Foundation/currenciyProtocol",
          {},
          { headers }
        );

        // 检查响应数据是否存在
        if (res && res.data) {
          // 处理响应数据
          let data = res.data;
          if (data && data.length > 0) {
            this.chainList = data.filter((item) => {
              return item.code === val;
            }); // 假设数据中有 usdtBalance 属性
          }
        } else {
          console.error("Invalid res:", res);
        }
      } catch (error) {
        console.error("Error fetching USDT balance:", error);
      }
    },
    //                                             axios 获取跟踪设置  get 请求数据
    async getTrackingGroupPageList() {
      try {
        // 添加请求头部
        const headers = {
          "Content-Type": "application/json",
          "auth-id": "D1g4j7cXy578MvZbLQqWzdUeNG",
          // 可以根据需要添加其他头部信息
        };

        // 发送 POST 请求
        const { data: res } = await axios.post(
          "https://nxapi.seek940.com/wallet/api/v1/Tracking/GetTrackingGroupPageList",
          // "http://ttmarket.ttmarket.com.cn:28080/wallet/api/v1/Tracking/GetTrackingGroupPageList",
          {
            area: this.virtualAddress,
            type: this.customerType,
            page: {
              index: this.page.index,
              size: this.page.size,
            },
          },
          { headers }
        );

        // 检查响应数据是否存在
        if (res && res.data) {
          // 处理响应数据
          res.data.forEach((element) => {
            element.account = 0;
            if (element.items) {
              // 内层循环遍历每个元素中的items数组
              element.items.forEach((item) => {
                let balanceValue = item.balance;
                if (typeof balanceValue === "string") {
                  balanceValue = parseFloat(balanceValue);
                  if (isNaN(balanceValue)) {
                    // 如果转换失败（不是有效的数字字符串），可以进行相应处理，比如跳过此次累加等
                    return this.$message.error("余额格式不正确");
                  }
                }
                element.account += balanceValue;
              });
            }
            // element.account  用以一个方法 保留小数点后面的数字，小数点的前面的每三位数字加一个逗号
            element.account = this.formatNumber(element.account);
          });
          // 处理第二遍
          res.data.forEach((element) => {
            // 内层循环遍历每个元素中的items数组
            if (element.items) {
              // 内层循环遍历每个元素中的items数组
              element.items.forEach((item) => {
                item.account = this.formatNumber(item.balance);
              });
            }
          });

          this.tableData = res.data; // 假设数据中有 usdtBalance 属性
          this.page = res.page; // 假设数据中有 page 属性
          this.result = this.formatNumber(res.totalBalance);
        } else {
          console.error("Invalid res:", res);
          this.$message.error("未分组");
        }
      } catch (error) {
        console.error("Error fetching USDT balance:", error);
      }
    },
    sumBalancesByCurrencyAndChain(data) {
      // 循环data中的每一个对象中 account 的值 的总和
      let accountSum = 0;

      data.forEach((item) => {
        // 尝试移除account值中的逗号，并转换为数字
        const cleanedAccount = item.account2;
        const accountValue = parseFloat(cleanedAccount); // 使用parseFloat来处理可能的小数点

        if (!isNaN(accountValue)) {
          accountSum += accountValue;
        } else {
          console.warn(`Invalid account value: ${item.account}. Skipping.`);
        }
      });

      // 保留小数点后三位并返回字符串
      return accountSum.toFixed(3);
    },
    // axios 获取跟踪设置
    async getCurrencyList() {
      try {
        // 添加请求头部
        const headers = {
          "Content-Type": "application/json",
          "auth-id": "D1g4j7cXy578MvZbLQqWzdUeNG",
          // 可以根据需要添加其他头部信息
        };

        // 发送 POST 请求
        const { data: res } = await axios.get(
          "https://nxapi.seek940.com/foundation/api/Foundation/currencies?type=crypto",
          // "http://ttmarket.ttmarket.com.cn:28080/foundation/api/Foundation/currencies?type=crypto",
          {},
          { headers }
        );

        // 检查响应数据是否存在
        if (res && res.data) {
          // 处理响应数据
          this.currencyList = res.data; // 假设数据中有 usdtBalance 属性
        } else {
          console.error("Invalid res:", res);
        }
      } catch (error) {
        console.error("Error fetching USDT balance:", error);
      }
    },
  },
};
</script>

<style lang="less" scoped>
div {
  box-sizing: border-box;
}
.container {
  width: 100%;
  height: 100%;
  .content {
    width: 100%;
    .search-box {
      > div {
        display: flex;
        align-items: center;
      }
    }
  }
  /deep/.el-form-item__content {
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
  .el-select {
    display: block;
  }
}
.main-container {
  width: 100%;

  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;

  .box {
    padding: 10px;
    margin: 5px;
    width: 24%;
    height: 200px;
    background-color: #f7f7f7;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: start;
    position: relative;
    border: 1px solid black;
    z-index: 97;
    .title {
      width: 100%;
      height: 20%;
      border-bottom: 1px solid black;
      z-index: 97;
      .el-icon-circle-close {
        position: absolute;
        top: -2px;
        right: -2px;
        font-size: 30px;
        cursor: pointer;
        color: red;
        z-index: 99 !important;
      }
      .name-box {
        width: 95%;
        display: flex;
        justify-content: space-between;

        .name-item {
          .name-label {
            font-size: 15px;
            font-weight: bold;
          }
          .name-value {
            font-size: 14px;
            color: red;
          }
        }

        .name-item:hover {
          background-color: black;
          color: white;
          cursor: pointer;
        }

        .count-label {
          font-size: 15px;
          font-weight: bold;
        }
        .count-value {
          font-size: 18px;
          color: red;
        }
        .count-decimal {
          font-size: 14px;
          color: red;
        }
      }
    }
    .content {
      width: 100%;
      height: 80%;
      overflow-y: scroll;

      .address-box {
        width: 100%;
        margin-top: 10px;
        cursor: pointer;
        display: flex;
        justify-content: space-between;
        .address-item {
          .address-label {
            font-size: 15px;
            font-weight: bold;
          }
          .address-value {
            font-size: 13px;
          }
        }
        .address-balance {
          .balance-label {
            font-size: 15px;
            color: green;
            font-weight: bold;
          }
          .balance-value {
            font-size: 11px;
            color: green;
          }
        }
      }
      .address-box:hover {
        background-color: black;
        color: white;
      }
    }
  }

  .content::-webkit-scrollbar {
    display: none;
  }
}
</style>
