<template>
  <div>
    <!-- 面包屑导航区 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>资产统计</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/home/assetStatistics' }">资产</el-breadcrumb-item>
      <el-breadcrumb-item>{{ name }} ({{ typeName }}) 流水</el-breadcrumb-item>
      <el-breadcrumb-item>固定额度：{{ formatAmount(creditBalance) }} {{ currencyCode }}</el-breadcrumb-item>
      <el-breadcrumb-item>临时额度：{{ formatAmount(temporaryQuota) }} {{ currencyCode }}</el-breadcrumb-item>
    </el-breadcrumb>
    <!-- 卡片视图 -->
    <el-card>
      <el-row :gutter="20">
        <!-- :gutter="20" 表示列直接间距20 -->
        <el-col :span="24">
          <el-select v-model="timeType" placeholder="请选择" @change="handleTimeType">
            <el-option v-for="item in TimeOptions" :key="item.value" :label="item.label" :value="item.value"> </el-option>
          </el-select>
          <el-date-picker v-model="queryInfo.queryDate" type="daterange" align="right" unlink-panels range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" value-format="timestamp" :picker-options="pickerOptions" @change="conditionChange"> </el-date-picker>&nbsp;
          <span>&nbsp;币种：</span>
          <el-select v-model="currencyID" placeholder="请选择" clearable @change="handleCurrency">
            <el-option v-for="item in CurrencyOptions" :key="item.currencyID" :label="item.currencyName" :value="item.currencyID">
              <div>{{ item.currencyName }}</div>
            </el-option>
          </el-select>
          &nbsp;
          <el-button type="primary" @click="getData()">查询</el-button>
        </el-col>
      </el-row>
      <el-table :data="data" row-key="id" border default-expand-all :tree-props="{ children: 'children', hasChildren: 'hasChildren' }" class="table-style">
        <el-table-column label="业务时间" align="center" width="170" v-if="timeType === 1">
          <template slot-scope="scope">
            <span>{{ scope.row.businessTime }}</span>
          </template>
        </el-table-column>
        <el-table-column label="创建时间" align="center" width="170" v-else>
          <template slot-scope="scope">
            <span>{{ scope.row.createdTime }}</span>
          </template>
        </el-table-column>
        <el-table-column label="金额" align="center" width="220">
          <template slot-scope="scope">
            <span :style="{ color: getColor(scope.row.changeAvailableAmt) }">
              {{ formatAmount(scope.row.changeAvailableAmt, scope.row.digit) }}
            </span>
          </template>
        </el-table-column>
        <el-table-column label="余额" width="220" align="center" v-if="timeType === 2">
          <template slot-scope="scope">
            <!-- 当等于创建时间展示 -->
            <span :style="{ color: getColor(scope.row.availableBalance) }">
              {{ formatAmount(scope.row.availableBalance, scope.row.digit) }}
            </span>
          </template>
        </el-table-column>
        <el-table-column label="余额" width="220" align="center" v-if="timeType === 1">
          <template slot-scope="scope">
            <!-- 当等于业务时间展示 -->
            <span :style="{ color: getColor(scope.row.bAvailableBalance) }">
              {{ formatAmount(scope.row.bAvailableBalance, scope.row.digit) }}
            </span>
          </template>
        </el-table-column>
        <el-table-column prop="currencyName" label="币种" width="110" align="center"></el-table-column>
        <el-table-column label="方向" align="center">
          <template slot-scope="scope">
            <div v-if="scope.row.type == 'cr' || scope.row.type == 'cw'">
              {{ (scope.row.nickName == null ? "" : scope.row.nickName) + "->" + (scope.row.deviceName == null ? "" : scope.row.deviceName) }}
            </div>
            <div v-else>
              {{ (scope.row.nickName == null ? "" : scope.row.nickName) + "->" + (scope.row.toNickName == null ? "" : scope.row.toNickName) }}
            </div>
          </template>
        </el-table-column>
        <el-table-column label="创建时间" width="170" align="center" v-if="timeType === 1">
          <template slot-scope="scope">
            <span>{{ scope.row.createdTime }}</span>
          </template>
        </el-table-column>
        <el-table-column label="业务时间" width="170" align="center" v-else>
          <template slot-scope="scope">
            <span>{{ scope.row.businessTime }}</span>
          </template>
        </el-table-column>
        <el-table-column label="业务类型" align="center" width="110">
          <template slot-scope="scope">
            <!-- {{ scope.row.outTransNo.startsWith("A") ? "交易单" : "" }} -->
            {{ scope.row.busiName[0].V }}
          </template>
        </el-table-column>
        <el-table-column label="备注" align="center">
          <template slot-scope="scope">
            <span v-if="scope.row.des && scope.row.des.length > 0">{{ scope.row.des[0].V }}</span>
            <span v-else>{{ scope.row.remarks }}</span>
          </template>
        </el-table-column>
        <el-table-column width="130" align="center">
          <template slot-scope="scope">
            <el-button type="warning" size="mini" @click="showDialog(scope.row.relateId)" icon="el-icon-view">查看业务单</el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页区域 -->
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="queryInfo.pageIndex" :page-sizes="[20, 50, 100]" :page-size="queryInfo.pageSize" layout="total, sizes, prev, pager, next, jumper" :total="pageInfo.total"></el-pagination>
    </el-card>
    <el-dialog :title="exchangeInfo.transCategoryTypeName" :visible.sync="exchangeDialogVisible" width="50%" center>
      <!-- 内容主体 -->
      <div style="display: flex; justify-content: space-between">
        <span>单号：{{ exchangeInfo.businessId }}</span
        ><span>制单人：{{ exchangeInfo.createOrderPerson }}</span
        ><span>制单时间：{{ exchangeInfo.createdTime }}</span>
      </div>
      <div style="display: flex; justify-content: space-between">
        <span>制单店铺：{{ exchangeInfo.storeName }}</span>
        <span>客户：{{ exchangeInfo.customerName }}({{ exchangeInfo.customerFileId }})</span><span>客户电话：{{ exchangeInfo.customerPhoneNumber }}</span>
      </div>
      <div style="display: flex; justify-content: space-between">
        <span v-if="exchangeInfo.bringAmount">自带：{{ formatAmount(exchangeInfo.bringAmount, exchangeInfo.digit) }}{{ exchangeInfo.buyCurrencyName }}</span
        ><span v-if="exchangeInfo.takingAmount">自取：{{ exchangeInfo.takingAmount }}{{ exchangeInfo.buyCurrencyName }}</span>
        <span v-if="exchangeInfo.transferFee">手续费：{{ formatAmount(exchangeInfo.transferFee, exchangeInfo.digit) }}{{ exchangeInfo.transferFeeCurrencyName }}</span>
      </div>
      <div style="display: flex; justify-content: space-between">
        <span>买入金额：{{ formatAmount(exchangeInfo.buyAmount, exchangeInfo.digit) }}{{ exchangeInfo.buyCurrencyName }}</span
        ><span>买汇价：{{ exchangeInfo.exchangeRate }}</span>
        <span>应付：{{ formatAmount(exchangeInfo.saleAmount, exchangeInfo.digit) }}{{ exchangeInfo.saleCurrencyName }}</span>
      </div>
      <el-divider content-position="left"></el-divider>
      <div v-for="item in exchangeInfo.details" :key="item.id">
        <div style="display: flex; justify-content: space-between">
          <div style="width: 70%; margin-right: 10px">
            <div v-for="subItem in item.childrens" :key="subItem.id" style="display: flex; justify-content: space-between; widows: 100%">
              <span v-if="subItem.bankOrOtherCode && subItem.bankOrOtherCode.toUpperCase() === 'WX'" style="display: flex; align-items: center">
                <span>{{ subItem.bankOrOtherName }}收款码：</span>
                <vue-qr :text="subItem.payment_code" :size="150" :margin="1" logoSrc="img/wechat.png" :correctLevel="3"></vue-qr>
                <span>{{ subItem.bankOrOtherName }}好友码：</span>
                <vue-qr :text="subItem.friendQrCode" :size="150" :margin="1" logoSrc="img/wechat.png" :correctLevel="3"></vue-qr>
              </span>
              <span v-else-if="subItem.bankOrOtherCode && subItem.bankOrOtherCode.toUpperCase() === 'ALIPAY'" style="display: flex; align-items: center">
                <span>{{ subItem.bankOrOtherName }}收款码：</span>
                <vue-qr :text="subItem.payment_code" :size="150" :margin="1" logoSrc="img/alipay.png" :correctLevel="3"></vue-qr>
                <span>{{ subItem.bankOrOtherName }}好友码：</span>
                <vue-qr :text="subItem.friendQrCode" :size="150" :margin="1" logoSrc="img/alipay.png" :correctLevel="3"></vue-qr>
              </span>
              <span v-else-if="subItem.bankOrOtherCode && subItem.bankOrOtherCode.toUpperCase() === 'VM'" style="display: flex; flex-direction: column; align-items: center">
                <span>{{ exchangeInfo.saleCurrencyCode }}-{{ subItem.friendQrCode }}</span>
                <vue-qr :text="subItem.payment_code" :size="150" :margin="1" :correctLevel="3"></vue-qr>
              </span>
              <span v-else style="display: flex; justify-content: space-between; width: 100%">
                <span>持卡人：{{ subItem.holderName }}</span>
                <span>卡号：{{ formatBankCardNumber(subItem.payment_code) }}</span>
                <span>银行：{{ subItem.bankOrOtherName }}</span>
                <span>开户地：{{ subItem.accountLocation }}</span>
              </span>
            </div>
          </div>
          <div style="display: flex; flex-direction: column; justify-content: center; width: 30%">
            <span style="text-align: end">金额：{{ formatAmount(item.amount, item.digit) }}{{ item.currencyName }}</span>
          </div>
        </div>
        <el-divider content-position="left"></el-divider>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button @click="exchangeDialogVisible = false">确定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import VueQr from "vue-qr";
export default {
  components: {
    VueQr,
  },
  data() {
    return {
      //传入参数
      name: "",
      typeName: "",
      currencyID: "1",
      currencyCode: "HKD",
      //日期快捷键
      pickerOptions: {
        shortcuts: [
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },

      //查询条件
      queryInfo: {
        accountId: "",
        pageIndex: 1,
        pageSize: 20,
        queryDate: "",
      },

      //币种选择项
      CurrencyOptions: [],

      TimeOptions: [
        {
          label: "业务时间",
          value: 1,
        },
        {
          label: "创建时间",
          value: 2,
        },
      ],
      timeType: 1,

      pageInfo: {
        total: 0,
      },
      data: [],
      accountList: [],
      exchangeDialogVisible: false,
      exchangeInfo: "",
      creditBalance: "",
      temporaryQuota: "",
    };
  },
  // 页面加载完成
  mounted() {
    // 接收参数
    this.queryInfo.accountId = this.$route.query.accountId;
    this.name = this.$route.query.name;
    this.typeName = this.$route.query.typeName;
    this.platform = this.$route.query.platform;
    // 获取本地额度数据
    this.accountList = JSON.parse(localStorage.getItem("accountList"));
    this.getAccountLimit();
    this.getCurrency();
    this.getData();
  },
  created() {},
  methods: {
    // 切换额度
    getAccountLimit() {
      if (this.accountList && this.accountList.length > 0) {
        this.accountList.forEach((item) => {
          if (item.currencyCode == this.currencyCode) {
            this.$set(this, "creditBalance", item.creditBalance);
            this.$set(this, "temporaryQuota", item.temporaryQuota);
          }
        });
      }
    },
    //获取流水
    async getData() {
      let et = "";
      if (this.queryInfo.queryDate && this.queryInfo.queryDate.length > 1) {
        et = new Date(this.queryInfo.queryDate[1]).format("yyyy-MM-dd 23:59:59");
        et = new Date(et).getTime();
      }
      const { data: res } = await this.$http.get("/api/TradeCenterTotal/GetAccountFlow", {
        params: {
          timeType: this.timeType,
          accountId: this.queryInfo.accountId,
          pageIndex: this.queryInfo.pageIndex,
          pageSize: this.queryInfo.pageSize,
          startTime: this.queryInfo.queryDate && this.queryInfo.queryDate.length > 0 ? this.queryInfo.queryDate[0] / 1000 : "",
          endTime: this.queryInfo.queryDate && this.queryInfo.queryDate.length > 1 ? et / 1000 : "",
          currencyID: this.currencyID,
        },
      });
      if (res.code == -1) return this.$message.error(res.message);
      res.data.list.forEach((v) => {
        v.busiName = JSON.parse(v.busiTypeName);
        v.des = JSON.parse(v.dictionaryRemark);
        v.createdTime = new Date(parseFloat(v.createdTime) * 1000).format("yyyy-MM-dd hh:mm:ss");
        v.businessTime = new Date(parseFloat(v.businessTime) * 1000).format("yyyy-MM-dd hh:mm:ss");
      });
      this.data = res.data.list;
      this.pageInfo = res.data.pageInfo;
      this.CurrencyOptions.forEach((item) => {
        if (item.currencyID == this.currencyID) {
          this.currencyCode = item.currencyCode;
        }
      });
      this.getAccountLimit();
    },
    //获取币种
    async getCurrency() {
      const { data: res } = await this.$http.get("/api/TradeCenterTotal/GetCurrency");
      if (res.code == -1) return this.$message.error(res.message);
      this.CurrencyOptions = res.data;
    },
    //业务单详情
    async showDialog(businessId) {
      if (businessId.startsWith("A")) {
        const { data: res } = await this.$http.get("platform-api/cash940/Total/ExchangeDetail", {
          params: {
            businessId: businessId,
          },
        });
        if (res.code == -1) return this.$message.error(res.message);
        if (res.data == undefined) return this.$message.error("未找到业务单，或已删除");
        //单据类型
        if (res.data.transCategoryType.toUpperCase() === "TS_CASH2CASH") {
          res.data.transCategoryTypeName = "现金对现金交易单";
        } else if (res.data.transCategoryType.toUpperCase() === "TS_PRE2CASH") {
          res.data.transCategoryTypeName = "余额对现金交易单";
        } else if (res.data.transCategoryType.toUpperCase() === "TS_CASH2REMITTANCE" || res.data.transCategoryType.toUpperCase() === "TS_PRE2REMITTANCE") {
          if (res.data.transCategoryType.toUpperCase() === "TS_CASH2REMITTANCE") {
            res.data.transCategoryTypeName = "现金对代付交易单";
          } else {
            res.data.transCategoryTypeName = "余额对代付交易单";
          }
          // 把或关系的组装到一个数组
          let details = [];
          if (res.data && res.data.exchangeDetails) {
            res.data.exchangeDetails.forEach((element) => {
              if (details.length === 0) {
                let childrens = [];
                childrens.push(element);
                details.push({
                  amount: element.amount,
                  currencyName: element.currencyName,
                  childrens: childrens,
                });
              } else {
                let exists = false;
                details.forEach((v) => {
                  v.childrens.forEach((c) => {
                    if (element.paymentOrFlag === c.paymentOrFlag) {
                      v.childrens.push(element);
                      exists = true;
                    }
                  });
                });
                if (exists === false) {
                  let childrens = [];
                  childrens.push(element);
                  details.push({
                    amount: element.amount,
                    currencyName: element.currencyName,
                    childrens: childrens,
                  });
                }
              }
            });
          }
          res.data.details = details;
        }
        this.exchangeInfo = res.data;
        this.exchangeDialogVisible = true;
      } else {
        this.$message.error("待开发的单据类型详情展示");
      }
    },
    // 整数时显示红色，负数时显示绿色
    getColor(amount) {
      return amount >= 0 ? "green" : "red";
    },
    //查询条件变化
    conditionChange() {
      this.queryInfo.pageIndex = 1;
    },
    // 监听 pageSize改变的事件
    handleSizeChange(newSize) {
      this.queryInfo.pageSize = newSize;
      this.getData();
    },
    // 监听 页码值 改变事件
    handleCurrentChange(newSize) {
      this.queryInfo.pageIndex = newSize;
      this.getData();
    },
    //金额格式化
    formatAmount(amount, digit) {
      if (amount === undefined) {
        return "";
      }
      let n = Number(amount).toFixed(digit); //可变小数位

      if (isNaN(n)) return "";
      var parts = String(n).split(".");
      var integerPart = parts[0];
      var decimalPart = parts.length > 1 ? "." + parts[1] : "";

      // 每三位添加一个逗号
      integerPart = integerPart.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");

      return integerPart + decimalPart;
    },
    //银行卡号格式化
    formatBankCardNumber(bankCardNumber) {
      // 去除空格并转换为字符串类型
      bankCardNumber = String(bankCardNumber).replace(/ /g, "");

      if (bankCardNumber.length < 16 || bankCardNumber.length > 20) {
        return bankCardNumber;
      } else {
        var formattedNumber = "";

        for (var i = 0; i < bankCardNumber.length; i++) {
          formattedNumber += bankCardNumber[i];

          // 每四位添加一个空格分隔
          if ((i + 1) % 4 === 0 && i !== bankCardNumber.length - 1) {
            formattedNumber += " ";
          }
        }

        return formattedNumber;
      }
    },
    //监听 币种改变
    handleCurrency(v) {
      this.currencyID = v;
    },
    handleTimeType(v) {
      this.timeType = v;
    },
  },
};
</script>

<style lang="less" scoped>
.table-style {
  /deep/.el-table__cell {
    padding: 10px 0;
  }
}
</style>
