<template>
  <div class="currencyStatistics">
    <div class="container">
      <!-- 面包屑导航区 -->
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>数据统计</el-breadcrumb-item>
        <el-breadcrumb-item>币种统计</el-breadcrumb-item>
      </el-breadcrumb>
      <div class="content">
        <div class="search-box">
          <div class="search-item">
            <div>选择商家：</div>
            <el-popover placement="bottom" width="200" v-model="tenantPopover" trigger="click">
              <div class="popover-content">
                <div v-for="(item, index) in tenantList" :key="index" @click="handleTenantChange(item)">
                  {{ item.tenant_name }}
                </div>
              </div>
              <el-button slot="reference">{{ tenantInfo.tenant_name }}</el-button>
            </el-popover>
          </div>
          <div class="search-item1">
            <div class="selectStore">选择店铺：</div>
            <div class="popover-content1">
              <div v-for="(item, index) in storeList" :key="index" :class="{ 'red-background': index === activeIndex }" @click="handleStoreChange(item, index)">
                {{ item.store_name }}
              </div>
            </div>
          </div>
        </div>
        <div style="width: 100%">
          <el-form label-width="80px" :model="formLabelAlign">
            <el-row>
              <el-col :span="6">
                <el-form-item label="开始时间:">
                  <el-date-picker @change="getAccountBalance" v-model="formLabelAlign.starTime" type="date" placeholder="选择日期"> </el-date-picker>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="结束时间:">
                  <el-date-picker @change="getAccountBalance" v-model="formLabelAlign.endTime" type="date" placeholder="选择日期"> </el-date-picker>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </div>
        <div class="account-balance-box">
          <el-tabs v-model="activeTabs" class="tabs-container">
            <el-tab-pane name="Legal">
              <div class="currency-box">
                <div class="currency-li row" v-for="(item, index) in cashBalanceList" :key="'cashBalanceList' + index">
                  <img style="width: 60px; height: 60px" :src="getImgUrl(item.currency_code)" :alt="item.currency_code" />
                  <div>
                    <div class="currency-name row">
                      <div>{{ item.currency_code }}</div>
                      <div>{{ item.currency_name }}</div>
                    </div>
                    <div class="currency-name row">
                      <div>买入金额：</div>
                      <div
                        :style="{
                          fontSize: '28px',
                          fontWeight: '700',
                          color: getBalanceColor(item.total_buy_amount),
                        }">
                        {{ Number(item.total_buy_amount).toLocaleString() }}
                      </div>
                    </div>
                    <div class="currency-name row">
                      <div>卖出金额：</div>
                      <div
                        :style="{
                          fontSize: '28px',
                          fontWeight: '700',
                          color: getBalanceColor(item.total_sale_amount),
                        }">
                        {{ Number(item.total_sale_amount).toLocaleString() }}
                      </div>
                    </div>
                    <div class="currency-name row">
                      <div>差值：</div>
                      <div
                        :style="{
                          fontSize: '28px',
                          fontWeight: '700',
                          color: getBalanceColor((Number(item.total_buy_amount) - Number(item.total_sale_amount)).toFixed(2)),
                        }">
                        {{ formatAmount((Number(item.total_buy_amount) - Number(item.total_sale_amount)).toFixed(2)) }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </el-tab-pane>
          </el-tabs>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import moment from "moment";
export default {
  name: "currencyStatistics",
  data() {
    return {
      activeIndex: 0,
      activeTabs: "Legal",
      tenantPopover: false,
      tenantList: [], // 商家列表
      tenantInfo: {}, // 商家信息
      storeList: [], // 店铺列表
      storeData: [], // 店铺列表
      storeInfo: {}, // 店铺信息
      cashBalanceList: [], // 账户列表

      formLabelAlign: {
        store: "",
        starTime: moment().format("YYYY-MM-DD"),
        endTime: moment().format("YYYY-MM-DD"),
      },
      intervalId: null, // 用于存储定时器 ID
    };
  },
  mounted() {
    const loginTenantsInfo = localStorage.getItem("loginTenantsInfo");
    try {
      this.tenantList = loginTenantsInfo ? JSON.parse(loginTenantsInfo) : [];
      this.tenantInfo = this.tenantList.length > 0 ? this.tenantList[0] : { tenant_name: "全部", tenant_id: "" };
    } catch (error) {
      this.tenantList = [];
      this.tenantInfo = { tenant_name: "全部", tenant_id: "" };
    }
    this.getStoreList();
    this.startAutoRefresh(); // 组件加载时启动定时器
  },
  methods: {
    startAutoRefresh() {
      // 每隔 5 秒刷新一次数据
      this.intervalId = setInterval(() => {
        this.getAccountBalance();
      }, 1000 * 60);
    },
    stopAutoRefresh() {
      if (this.intervalId) {
        clearInterval(this.intervalId);
        this.intervalId = null;
      }
    },
    /**
     * 根据账户余额返回颜色值
     *
     * @param balance 账户余额
     * @returns 如果余额大于等于0，返回'green'；否则返回'red'
     */
    getBalanceColor(balance) {
      const numBalance = Number(balance);
      return numBalance >= 0 ? "green" : "red";
    },
    //金额格式化
    formatAmount(amount) {
      // 去除小数点前后多余的0
      amount = parseFloat(amount);
      if (isNaN(amount)) return "";
      var parts = String(amount).split(".");
      var integerPart = parts[0];
      var decimalPart = parts.length > 1 ? "." + parts[1] : "";

      // 每三位添加一个逗号
      integerPart = integerPart.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");

      return integerPart + decimalPart;
    },
    // 获取商家店铺列表
    async getStoreList() {
      const { data: res } = await this.$http.get("v1/Store/GetStoreInfoByTenant", {
        params: { tenant: this.tenantInfo.tenant_id },
      });
      if (res.code == -1) return this.$message.error(res.message);
      res.data.unshift({
        store_id: "",
        store_name: "全部",
      });
      res.data = res.data.filter((item) => !item.store_id.includes("608322287283867648"));
      res.data = res.data.filter((item) => !item.store_id.includes("629155004615364608"));
      res.data = res.data.filter((item) => !item.store_id.includes("644089413139304448"));
      res.data = res.data.filter((item) => !item.store_id.includes("644089484933206016"));

      this.storeList = res.data.filter((item) => !item.store_name.includes("停用"));
      this.storeInfo = res.data[0];
      this.storeList.forEach((item) => {
        if (item.store_name != "全部" && item.store_id != "") {
          this.storeData.push(item.store_id);
        }
      });
      this.getAccountBalance();
    },

    // 商家选择
    handleTenantChange(item) {
      this.tenantInfo = item;
      this.getStoreList();
      this.tenantPopover = false;
    },

    // 店铺选择
    handleStoreChange(item, index) {
      this.storeData = [];
      this.activeIndex = index;
      this.storeInfo = item;
      if (item.store_name == "全部" && item.store_id == "") {
        this.storeList.forEach((p) => {
          if (p.store_name != "全部" && p.store_id != "") {
            this.storeData.push(p.store_id);
          }
        });
      } else {
        this.storeData.push(item.store_id);
      }
      this.getAccountBalance();
    },
    async getAccountBalance() {
      const startTime = moment(this.formLabelAlign.starTime);
      const endTime = moment(this.formLabelAlign.endTime);
      const newStartTime = startTime.format("YYYY-MM-DD") + " 00:00:00";
      const newEndTime = endTime.format("YYYY-MM-DD") + " 23:59:59";
      const { data: res } = await this.$http.post("api/Report/Report3", {
        storeList: this.storeData,
        st: newStartTime,
        et: newEndTime,
      });
      if (res.code == -1) return this.$message.error(res.message);
      this.cashBalanceList = res.data;
    },

    // 根据币种code获取币种图片
    getImgUrl(currencyCode) {
      try {
        return require(`@/assets/currency/${currencyCode}.png`);
      } catch (error) {
        console.error(`Image not found for currency code: ${currencyCode}`, error);
        return ""; // 默认图片
      }
    },
  },

  beforeRouteLeave(to, from, next) {
    this.stopAutoRefresh();
    next();
  },
};
</script>
<style lang="less" scoped>
.red-background {
  background-color: red;
}
.container {
  width: 100%;
  height: 100%;
  .content {
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    .search-box {
      width: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      .search-item {
        display: flex;
        align-items: center;
        margin-right: 30px;
        > :nth-child(1) {
          width: 80px;
        }
      }
      .search-item1 {
        width: calc(100% - 200px);
        display: flex;
        align-items: center;
        .selectStore {
          width: 80px;
        }
      }
    }
    .account-balance-box {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
      /deep/.el-tabs__nav-scroll {
        padding: 10px 0;
      }
      .tabs-container {
        width: 100%;
        height: 100%;
        /deep/.el-tabs__item {
          font-size: 16px;
        }
        .currency-box {
          padding: 20px 5px;
          box-sizing: border-box;
          overflow: auto;
          width: 100%;
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          justify-content: flex-start;
          .currency-li {
            width: 19.4%;
            margin: 10px 5px 0;
            cursor: pointer;
            display: flex;
            border: 1px solid #000;
            padding: 0 0 0 20px;
            align-items: center;
            box-sizing: border-box;
            > div {
              color: #333333;
              margin-left: 10px;
            }
            .currency-name {
              padding: 5px 0;
              align-items: center;
              > div {
                height: 30px;
                line-height: 30px;
                padding: 0 5px;
                font-size: 18px;
              }
            }
          }
        }
      }
    }
  }
}
.popover-content {
  width: 100%;
  max-height: 600px;
  overflow: auto;
  padding: 20px 0;
  box-sizing: border-box;

  > div {
    width: 90%;
    height: 50px;
    box-sizing: border-box;
    cursor: pointer;
    font-size: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 10px;
    box-shadow: 0px 0px 5px 0px rgb(135, 135, 135);
  }
}
.popover-content1 {
  width: calc(100% - 80px);
  padding: 10px 0;
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  > div {
    cursor: pointer; // 添加小手样式
    min-width: 100px;
    max-width: 500px;
    white-space: nowrap;
    padding: 0 10px;
    height: 40px;
    box-sizing: border-box;
    font-size: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 10px;
    box-shadow: 0px 0px 5px 0px rgb(135, 135, 135);
  }
}
</style>
