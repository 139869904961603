<template>
  <div class="transactionAccounting">
    <!-- 面包屑导航区 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>统计</el-breadcrumb-item>
      <el-breadcrumb-item>往来记账</el-breadcrumb-item>
    </el-breadcrumb>
    <el-card class="box-card">
      <el-form label-width="80px" :model="formLabelAlign">
        <el-row>
          <el-col :span="5">
            <el-form-item label="开始时间:">
              <el-date-picker v-model="formLabelAlign.starTime" type="date" @change="handleDateChangeSt" placeholder="选择日期"> </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="5">
            <el-form-item label="结束时间:">
              <el-date-picker v-model="formLabelAlign.endTime" @change="handleDateChangeEt" type="date" placeholder="选择日期"> </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <el-form-item label="商家:">
              <el-select v-model="formLabelAlign.tenantId" @change="getStoreData" placeholder="请选择">
                <el-option v-for="(item, index) in tenantList" :key="index" :label="item.tenant_name" :value="item.tenant_id"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="5">
            <el-form-item label="选择店铺:">
              <el-select @change="handleStoreChange" v-model="formLabelAlign.storeId" collapse-tags placeholder="请选择">
                <el-option v-for="item in storeList" :key="item.value" :label="item.store_name" :value="item.store_id"> </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <el-form-item label="选择币种:">
              <el-select clearable v-model="formLabelAlign.currencyID" placeholder="请选择">
                <el-option v-for="(item, index) in currencyList" :key="index" :label="item.currency_name" :value="item.currency_id"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="3">
            <el-form-item label="不同店铺:">
              <el-checkbox border style="width: 100% !important; height: 100%" v-model="formLabelAlign.differentStore"></el-checkbox>
            </el-form-item>
          </el-col>
          <el-col :span="3">
            <el-button type="primary" @click="search" icon="el-icon-search">搜索</el-button>
          </el-col>
        </el-row>
      </el-form>
    </el-card>
    <el-card style="margin-top: 20px">
      <el-tabs v-model="activeName">
        <el-tab-pane label="发布交易单欠其他店,对方对数收款给我" name="first">
          <el-button @click="logarithmicTableExport">导出</el-button>
          <el-table :data="logarithmicTableData" style="width: 100%">
            <el-table-column label="流水号" prop="store_account_flow_id"></el-table-column>
            <el-table-column label="账户店铺" prop="account_store_name"></el-table-column>
            <el-table-column label="流水金额" prop="store_account_flow_amount">
              <template slot-scope="scope">
                <span>{{ formatAmount(scope.row.store_account_flow_amount) }}</span>
              </template>
            </el-table-column>
            <el-table-column label="流水币种" prop="store_account_flow_currency_name"></el-table-column>
            <el-table-column label="交易单号" prop="store_exchange_no"></el-table-column>
            <el-table-column label="交易类型" prop="exchange_type_name"></el-table-column>
            <el-table-column label="交易单状态" prop="exchange_status_name"></el-table-column>
            <el-table-column label="是否隐藏" width="70px" prop="exchange_hide_flag">
              <template slot-scope="scope">
                <span v-if="scope.row.exchange_hide_flag == 1">是</span>
                <span v-else>否</span>
              </template>
            </el-table-column>
            <el-table-column label="交易单的店铺" prop="exchange_store_name"></el-table-column>
            <el-table-column label="交易单的客户名字" prop="store_exchange_archives_name">
              <template slot-scope="scope">
                <span>{{ scope.row.store_exchange_archives_name }} ({{ scope.row.store_exchange_archives_no }})</span>
              </template>
            </el-table-column>
            <el-table-column label="交易单详情金额" prop="store_exchange_detail_amount">
              <template slot-scope="scope">
                <span>{{ formatAmount(scope.row.store_exchange_detail_amount) }}</span>
              </template>
            </el-table-column>
            <el-table-column label="交易单详情的币种" prop="store_exchange_detail_currency_name"></el-table-column>
            <el-table-column label="对手的收款单号" prop="store_receipt_no"></el-table-column>
            <el-table-column label="对手的收款单状态" prop="store_receipt_status_name"></el-table-column>
            <el-table-column label="对手的收款店铺" prop="store_receipt_store_name"></el-table-column>
            <el-table-column label="对手的收款单的客户名字" prop="store_receipt_archives_name">
              <template slot-scope="scope">
                <span>{{ scope.row.store_receipt_archives_name }} ({{ scope.row.store_receipt_archives_no }})</span>
              </template>
            </el-table-column>
            <el-table-column label="对手的收款单的金额" prop="store_receipt_amount">
              <template slot-scope="scope">
                <span>{{ formatAmount(scope.row.store_receipt_amount) }}</span>
              </template>
            </el-table-column>
          </el-table>
          <el-pagination :page-size="logarithmicPageInfo.pageSize" layout="prev, pager, next" :total="logarithmicPageInfo.total" @current-change="handleLogarithmicTableChange"> </el-pagination>
        </el-tab-pane>

        <el-tab-pane label="发布交易单欠其他店,对方店铺账户付款给我" name="second">
          <el-button @click="storeAccountPaymentTableExport">导出</el-button>
          <el-table :data="storeAccountPaymentTableData" style="width: 100%">
            <el-table-column label="流水号" prop="store_account_flow_id"></el-table-column>
            <el-table-column label="账户店铺" prop="account_store_name"></el-table-column>
            <el-table-column label="流水金额" prop="store_account_flow_amount">
              <template slot-scope="scope">
                <span>{{ formatAmount(scope.row.store_account_flow_amount) }}</span>
              </template>
            </el-table-column>
            <el-table-column label="流水币种" prop="store_account_flow_currency_name"></el-table-column>
            <el-table-column label="交易单号" prop="store_exchange_no"></el-table-column>
            <el-table-column label="交易类型" prop="exchange_type_name"></el-table-column>
            <el-table-column label="交易单状态" prop="exchange_status_name"></el-table-column>
            <el-table-column label="是否隐藏" prop="exchange_hide_flag">
              <template slot-scope="scope">
                <span v-if="scope.row.exchange_hide_flag == 1">是</span>
                <span v-else>否</span>
              </template>
            </el-table-column>
            <el-table-column label="交易单的店铺" prop="exchange_store_name"></el-table-column>
            <el-table-column label="交易单的客户名字" prop="store_exchange_archives_name">
              <template slot-scope="scope">
                <span>{{ scope.row.store_exchange_archives_name }} ({{ scope.row.store_exchange_archives_no }})</span>
              </template>
            </el-table-column>
            <el-table-column label="交易单详情金额" prop="store_exchange_detail_amount">
              <template slot-scope="scope">
                <span>{{ formatAmount(scope.row.store_exchange_detail_amount) }}</span>
              </template>
            </el-table-column>
            <el-table-column label="交易单详情的币种" prop="store_exchange_detail_currency_name"></el-table-column>
            <el-table-column label="对手的付款单号" prop="store_payment_no"></el-table-column>
            <el-table-column label="对手的付款单状态" prop="store_payment_status_name"></el-table-column>
            <el-table-column label="对手的付款单店铺" prop="store_payment_store_name"></el-table-column>
            <el-table-column label="对手的付款单的金额" prop="store_payment_amount">
              <template slot-scope="scope">
                <span>{{ formatAmount(scope.row.store_payment_amount) }}</span>
              </template>
            </el-table-column>
          </el-table>
          <el-pagination :page-size="storeAccountPaymentPageInfo.pageSize" layout="prev, pager, next" :total="storeAccountPaymentPageInfo.total" @current-change="handleStoreAccountPaymentTableChange"> </el-pagination>
        </el-tab-pane>
        <el-tab-pane label="制“其他客户”收款单,还其他店" name="third">
          <el-button @click="receiptTableExport">导出</el-button>
          <el-table :data="receiptTableData" style="width: 100%">
            <el-table-column label="流水号" prop="store_account_flow_id"></el-table-column>
            <el-table-column label="流水金额" prop="store_account_flow_amount">
              <template slot-scope="scope">
                <span>{{ formatAmount(scope.row.store_account_flow_amount) }}</span>
              </template>
            </el-table-column>
            <el-table-column label="流水币种" prop="store_account_flow_currency_name"></el-table-column>
            <el-table-column label="收款单号" prop="store_receipt_no"></el-table-column>
            <el-table-column label="收款单店名" prop="store_receipt_store_name"></el-table-column>
            <el-table-column label="收款单客户名字" prop="store_receipt_archives_name">
              <template slot-scope="scope">
                <span>{{ scope.row.store_receipt_archives_name }} ({{ scope.row.store_receipt_archives_no }})</span>
              </template>
            </el-table-column>
            <el-table-column label="收款单金额" prop="store_receipt_amount">
              <template slot-scope="scope">
                <span>{{ formatAmount(scope.row.store_receipt_amount) }}</span>
              </template>
            </el-table-column>
            <el-table-column label="对手的交易单号" prop="store_exchange_no"></el-table-column>
            <el-table-column label="对手的交易单类型" prop="exchange_type_name"></el-table-column>
            <el-table-column label="对手的交易单状态" prop="exchange_status_name"></el-table-column>
            <el-table-column label="是否隐藏" prop="exchange_hide_flag">
              <template slot-scope="scope">
                <span v-if="scope.row.exchange_hide_flag == 1">是</span>
                <span v-else>否</span>
              </template>
            </el-table-column>
            <el-table-column label="对手的交易单店铺" prop="exchange_store_name"></el-table-column>
            <el-table-column label="对手的交易单详情金额" prop="store_exchange_detail_amount">
              <template slot-scope="scope">
                <span>{{ formatAmount(scope.row.store_exchange_detail_amount) }}</span>
              </template>
            </el-table-column>
            <el-table-column label="对手的交易单客户名字" prop="store_exchange_archives_name">
              <template slot-scope="scope">
                <span> {{ scope.row.store_exchange_archives_name }} ({{ scope.row.store_exchange_archives_no }}) </span>
              </template>
            </el-table-column>
          </el-table>
          <el-pagination :page-size="receiptTablePageInfo.pageSize" layout="prev, pager, next" :total="receiptTablePageInfo.total" @current-change="handleReceiptTableChange"> </el-pagination>
        </el-tab-pane>
        <el-tab-pane label="制“其他客户”的店铺付款单,还其他店" name="fourth">
          <el-button @click="storePaymentTableExport">导出</el-button>
          <el-table :data="storePaymentTableData" style="width: 100%">
            <el-table-column label="流水编号" prop="store_account_flow_id"></el-table-column>
            <el-table-column label="流水金额" prop="store_account_flow_amount">
              <template slot-scope="scope">
                <span>{{ formatAmount(scope.row.store_account_flow_amount) }}</span>
              </template>
            </el-table-column>
            <el-table-column label="流水币种" prop="store_account_flow_currency_name"></el-table-column>
            <el-table-column label="付款单号" prop="store_payment_no"></el-table-column>
            <el-table-column label="付款单店铺" prop="store_payment_store_name"></el-table-column>
            <el-table-column label="付款金额" prop="store_payment_amount">
              <template slot-scope="scope">
                <span>{{ formatAmount(scope.row.store_payment_amount) }}</span>
              </template>
            </el-table-column>
            <el-table-column label="对手的交易单号" prop="store_exchange_no"></el-table-column>
            <el-table-column label="对手的交易单店铺" prop="exchange_store_name"></el-table-column>
            <el-table-column label="对手的交易单类型" prop="exchange_type_name"></el-table-column>
            <el-table-column label="对手的交易单状态" prop="exchange_status_name"></el-table-column>
            <el-table-column label="是否隐藏" prop="exchange_hide_flag">
              <template slot-scope="scope">
                <span v-if="scope.row.exchange_hide_flag == 1">是</span>
                <span v-else>否</span>
              </template>
            </el-table-column>
            <el-table-column label="对手的交易单详情金额" prop="store_exchange_detail_amount">
              <template slot-scope="scope">
                <span>{{ formatAmount(scope.row.store_exchange_detail_amount) }}</span>
              </template>
            </el-table-column>
            <el-table-column label="对手的交易单客户名字" prop="store_exchange_archives_name">
              <template slot-scope="scope">
                <span>{{ scope.row.store_exchange_archives_name }} ({{ scope.row.store_exchange_archives_no }})</span>
              </template>
            </el-table-column>
          </el-table>
          <el-pagination :page-size="storePaymentPageInfo.pageSize" layout="prev, pager, next" :total="storePaymentPageInfo.total" @current-change="handleStorePaymentTableChange"> </el-pagination>
        </el-tab-pane>
      </el-tabs>
    </el-card>
  </div>
</template>
<script>
import moment from "moment";
import * as XLSX from "xlsx";
export default {
  data() {
    return {
      formLabelAlign: {
        starTime: "",
        endTime: "",
        st: "", //开始时间
        et: "", //结束时间
        tenantId: "",
        storeId: "", //门店选择
        currencyID: "", //币种
        differentStore: false, //是否相同
      },
      tenantList: [], //商家列表
      storeList: [], //门店列表
      currencyList: [], //币种数据
      activeName: "first", // 当前激活的tab
      logarithmicTableData: [], // 表格数据
      logarithmicPageInfo: {
        pageIndex: 1,
        pageSize: 10,
        pageCount: 0,
        total: 0,
      }, //first表格分页信息

      storeAccountPaymentTableData: [], // second表格数据
      storeAccountPaymentPageInfo: {
        pageIndex: 1,
        pageSize: 10,
        pageCount: 0,
        total: 0,
      }, //second表格分页信息
      receiptTableData: [], // third表格数据
      receiptTablePageInfo: {
        pageIndex: 1,
        pageSize: 10,
        pageCount: 0,
        total: 0,
      }, //third表格分页信息
      storePaymentTableData: [], // fourth表格数据
      storePaymentPageInfo: {
        pageIndex: 1,
        pageSize: 10,
        pageCount: 0,
        total: 0,
      }, //fourth表格分页信息
      storeName: "", //门店名称
    };
  },
  mounted() {
    this.getTenantList(); //获取商家列表
  },
  methods: {
    // 总搜索
    search() {
      if (!this.formLabelAlign.starTime) {
        this.formLabelAlign.st = this.getStartOfDay();
      }
      if (!this.formLabelAlign.endTime) {
        this.formLabelAlign.et = this.getEndOfDay();
      }
      if (!this.formLabelAlign.tenantId) {
        this.$message.error("请选择商家");
        return;
      }
      if (!this.formLabelAlign.storeId) {
        this.$message.error("请选择店铺");
        return;
      }
      this.logarithmicPageInfo.pageIndex = 1;
      this.getStoreAccountFlowInReceipt();
      this.storeAccountPaymentPageInfo.pageIndex = 1;
      this.getStoreAccountFlowInStorePay();
      this.receiptTablePageInfo.pageIndex = 1;
      this.getStoreAccountFlowOutReceipt();
      this.storePaymentPageInfo.pageIndex = 1;
      this.getStoreAccountFlowOutStorePay();
    },
    getStartOfDay() {
      return moment().startOf("day").format("YYYY-MM-DD HH:mm:ss");
    },
    getEndOfDay() {
      return moment(new Date()).format("YYYY-MM-DD") + " 23:59:59";
    },
    //金额格式化
    formatAmount(amount) {
      // 去除小数点前后多余的0
      amount = parseFloat(amount);

      if (isNaN(amount)) return "";
      var parts = String(amount).split(".");
      var integerPart = parts[0];
      var decimalPart = parts.length > 1 ? "." + parts[1] : "";

      // 每三位添加一个逗号
      integerPart = integerPart.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");

      return integerPart + decimalPart;
    },
    // 修改时间格式
    handleDateChangeSt(val) {
      if (val) {
        this.formLabelAlign.st = moment(val).format("YYYY-MM-DD") + " 00:00:00";
      }
    },
    handleDateChangeEt(val) {
      if (val) {
        this.formLabelAlign.et = moment(val).format("YYYY-MM-DD") + " 23:59:59";
      }
    },
    // 获取商家列表
    getTenantList() {
      const tenantsJson = localStorage.getItem("loginTenantsInfo");
      let tenants = [];
      if (tenantsJson) {
        tenants = JSON.parse(tenantsJson);
      }
      this.tenantList = tenants;
    },
    // 获取门店信息
    getStoreData() {
      this.storeId = "";
      this.$http
        .get("v1/Store/GetStoreInfoByTenant", {
          params: { tenant: this.formLabelAlign.tenantId },
        })
        .then(({ data: res }) => {
          this.storeList = res.data;
        });
    },
    // 店铺选择
    handleStoreChange(item) {
      // 根据 item 去找看他选择那个店铺
      this.storeList.find((item1) => {
        if (item1.store_id == item) {
          this.storeName = item1.store_name;
        }
      });

      this.$http
        .get("/StoreCurrency/GetEnable", {
          params: {
            store: item,
            tenant: this.formLabelAlign.tenantId,
          },
        })
        .then((res) => {
          if (res.code == -1) return this.$message.error(res.message);
          this.currencyList = res.data.data;
        });
    },
    // 获取first数据
    async getStoreAccountFlowInReceipt() {
      try {
        const { data: res } = await this.$http.get("/StoreAccount/GetStore2StoreAccountFlowInReceipt", {
          params: {
            st: this.formLabelAlign.st,
            et: this.formLabelAlign.et,
            storeID: this.formLabelAlign.storeId,
            currencyID: this.formLabelAlign.currencyID,
            differentStore: this.formLabelAlign.differentStore,
            pageIndex: this.logarithmicPageInfo.pageIndex,
            pageSize: this.logarithmicPageInfo.pageSize,
          },
        });
        if (res.code !== 1) {
          this.$message.error(res.message);
          return;
        }
        this.logarithmicTableData = res.data;
        this.logarithmicPageInfo = res.pageInfo;
      } catch (error) {
        // 这里不会捕获到503错误，因为已经在拦截器中处理了
        this.$message.error("接口报错了");
      }
    },
    //   first表格分页
    handleLogarithmicTableChange(val) {
      this.logarithmicPageInfo.pageIndex = val;
      this.getStoreAccountFlowInReceipt();
    },
    //  导出数据
    async logarithmicTableExport() {
      const { data: res } = await this.$http.get("/StoreAccount/GetStore2StoreAccountFlowInReceipt", {
        params: {
          st: this.formLabelAlign.st,
          et: this.formLabelAlign.et,
          storeID: this.formLabelAlign.storeId,
          currencyID: this.formLabelAlign.currencyID,
          differentStore: this.formLabelAlign.differentStore,
          pageIndex: 1,
          pageSize: this.logarithmicPageInfo.total,
        },
      });
      if (res.code !== 1) return this.$message.error(res.message);
      let logarithmicData = [];
      logarithmicData = res.data.filter((item) => item.exchange_hide_flag !== "1"); // 过滤掉需要隐藏的数据
      let date = moment().format("MM-DD HH:mm");

      const ws_name = `${this.storeName}-tab1-${date}`;

      const ws_data = [
        ["流水号", "账户店铺", "流水金额", "流水币种", "交易单号", "交易类型", "交易单状态", "是否隐藏", "交易单的店铺", "交易单的客户名字", "交易单详情金额", "交易单详情的币种", "对手的收款单号", "对手的收款单状态", "对手的收款单店铺", "对手的收款单的客户名字", "对手的收款单的金额"],
        ...logarithmicData.map((item) => [
          item.store_account_flow_id,
          item.account_store_name,
          this.formatAmount(item.store_account_flow_amount),
          item.store_account_flow_currency_name,
          item.store_exchange_no,
          item.exchange_type_name,
          item.exchange_status_name,
          item.exchange_hide_flag == 1 ? "是" : "否",
          item.exchange_store_name,
          `${item.store_exchange_archives_name} (${item.store_exchange_archives_no})`,
          this.formatAmount(item.store_exchange_detail_amount),
          item.store_exchange_detail_currency_name,
          item.store_receipt_no,
          item.store_receipt_status_name,
          item.store_receipt_store_name,
          `${item.store_receipt_archives_name} (${item.store_receipt_archives_no})`,
          this.formatAmount(item.store_receipt_amount),
        ]),
      ];

      // 创建一个新的工作表
      const ws = XLSX.utils.aoa_to_sheet(ws_data);

      // 创建一个新的工作簿并添加工作表
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, ws_name);

      // 写入字符串
      const wbout = XLSX.write(wb, { bookType: "xlsx", type: "binary" });

      // 保存文件
      this.saveFile(new Blob([this.binaryStringToArrayBuffer(wbout)], { type: "application/octet-stream" }), {
        type: "application/octet-stream",
        headers: { "Content-Disposition": `attachment;filename=${ws_name}.xlsx` },
      });
    },

    //   获取second数据
    async getStoreAccountFlowInStorePay() {
      const { data: res } = await this.$http.get("/StoreAccount/GetStore2StoreAccountFlowInStorePay", {
        params: {
          st: this.formLabelAlign.st,
          et: this.formLabelAlign.et,
          storeID: this.formLabelAlign.storeId,
          currencyID: this.formLabelAlign.currencyID,
          differentStore: this.formLabelAlign.differentStore,
          pageIndex: this.storeAccountPaymentPageInfo.pageIndex,
          pageSize: this.storeAccountPaymentPageInfo.pageSize,
        },
      });
      if (res.code !== 1) return this.$message.error(res.message);
      this.storeAccountPaymentTableData = res.data;
      this.storeAccountPaymentPageInfo = res.pageInfo;
    },
    //   second表格分页
    handleStoreAccountPaymentTableChange(val) {
      this.storeAccountPaymentPageInfo.pageIndex = val;
      this.getStoreAccountFlowInStorePay();
    },
    //  导出数据
    async storeAccountPaymentTableExport() {
      const { data: res } = await this.$http.get("/StoreAccount/GetStore2StoreAccountFlowInStorePay", {
        params: {
          st: this.formLabelAlign.st,
          et: this.formLabelAlign.et,
          storeID: this.formLabelAlign.storeId,
          currencyID: this.formLabelAlign.currencyID,
          differentStore: this.formLabelAlign.differentStore,
          pageIndex: 1,
          pageSize: this.storeAccountPaymentPageInfo.total,
        },
      });
      if (res.code !== 1) return this.$message.error(res.message);
      let storeAccountPaymentData = res.data.filter((item) => item.exchange_hide_flag !== "1"); // 过滤掉需要隐藏的数据

      //  用moment获取当前时间
      let date = moment().format("MM-DD HH:mm");

      // 准备导出的数据
      const ws_name = `${this.storeName}-tab2-${date}`;
      const ws_data = [
        ["流水号", "账户店铺", "流水金额", "流水币种", "交易单号", "交易类型", "交易单状态", "是否隐藏", "交易单的店铺", "交易单的客户名字", "交易单详情金额", "交易单详情的币种", "对手的付款单号", "对手的付款单状态", "对手的付款单店铺", "对手的付款单的金额"],
        ...storeAccountPaymentData.map((item) => [
          item.store_account_flow_id,
          item.account_store_name,
          this.formatAmount(item.store_account_flow_amount),
          item.store_account_flow_currency_name,
          item.store_exchange_no,
          item.exchange_type_name,
          item.exchange_status_name,
          item.exchange_hide_flag == 1 ? "是" : "否",
          item.exchange_store_name,
          `${item.store_exchange_archives_name} (${item.store_exchange_archives_no})`,
          this.formatAmount(item.store_exchange_detail_amount),
          item.store_exchange_detail_currency_name,
          item.store_payment_no,
          item.store_payment_status_name,
          item.store_payment_store_name,
          this.formatAmount(item.store_payment_amount),
        ]),
      ];

      // 创建一个新的工作表
      const ws = XLSX.utils.aoa_to_sheet(ws_data);

      // 创建一个新的工作簿并添加工作表
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, ws_name);

      // 写入字符串
      const wbout = XLSX.write(wb, { bookType: "xlsx", type: "binary" });

      // 保存文件
      this.saveFile(new Blob([this.binaryStringToArrayBuffer(wbout)], { type: "application/octet-stream" }), { type: "application/octet-stream", headers: { "Content-Disposition": "attachment;filename=" + ws_name + ".xlsx" } });
    },

    // 辅助函数，将二进制字符串转换为 ArrayBuffer
    binaryStringToArrayBuffer(s) {
      const buffer = new ArrayBuffer(s.length);
      const uint = new Uint8Array(buffer);
      for (let i = 0; i < s.length; i++) {
        uint[i] = s.charCodeAt(i) & 0xff;
      }
      return buffer;
    },

    // 辅助函数，用于在客户端保存文件
    saveFile(blob, options) {
      const createObjectURL = window.URL.createObjectURL(blob);
      const createElement = document.createElement("a");
      document.body.appendChild(createElement);
      createElement.style = "display: none";
      createElement.href = createObjectURL;
      createElement.download = options.headers["Content-Disposition"].split(";")[1].split("=")[1].replace(/"/g, "");
      createElement.click();
      window.URL.revokeObjectURL(createObjectURL);
    },
    //   获取third数据
    async getStoreAccountFlowOutReceipt() {
      const { data: res } = await this.$http.get("/StoreAccount/GetStore2StoreAccountFlowOutReceipt", {
        params: {
          st: this.formLabelAlign.st,
          et: this.formLabelAlign.et,
          storeID: this.formLabelAlign.storeId,
          currencyID: this.formLabelAlign.currencyID,
          differentStore: this.formLabelAlign.differentStore,
          pageIndex: this.receiptTablePageInfo.pageIndex,
          pageSize: this.receiptTablePageInfo.pageSize,
        },
      });
      if (res.code !== 1) return this.$message.error(res.message);
      this.receiptTableData = res.data;
      this.receiptTablePageInfo = res.pageInfo;
    },

    //   third表格分页
    handleReceiptTableChange(val) {
      this.receiptTablePageInfo.pageIndex = val;
      this.getStoreAccountFlowOutReceipt();
    },
    //  导出数据
    async receiptTableExport() {
      const { data: res } = await this.$http.get("/StoreAccount/GetStore2StoreAccountFlowOutReceipt", {
        params: {
          st: this.formLabelAlign.st,
          et: this.formLabelAlign.et,
          storeID: this.formLabelAlign.storeId,
          currencyID: this.formLabelAlign.currencyID,
          differentStore: this.formLabelAlign.differentStore,
          pageIndex: 1,
          pageSize: this.receiptTablePageInfo.total,
        },
      });
      if (res.code !== 1) return this.$message.error(res.message);
      let receiptData = res.data.filter((item) => item.exchange_hide_flag !== "1"); // 过滤掉需要隐藏的数据
      let date = moment().format("MM-DD HH:mm");

      const wsName = `${this.storeName}-tab3-${date}`;
      const wsData = [
        ["流水号", "流水金额", "流水币种", "收款单号", "收款单店名", "收款单客户名字", "收款单金额", "对手的交易单号", "对手的交易单类型", "对手的交易单状态", "是否隐藏", "对手的交易单店铺", "对手的交易单详情金额", "对手的交易单客户名字"],
        ...receiptData.map((item) => [
          item.store_account_flow_id,
          this.formatAmount(item.store_account_flow_amount),
          item.store_account_flow_currency_name,
          item.store_receipt_no,
          item.store_receipt_store_name,
          `${item.store_receipt_archives_name} (${item.store_receipt_archives_no})`,
          this.formatAmount(item.store_receipt_amount),
          item.store_exchange_no,
          item.exchange_type_name,
          item.exchange_status_name,
          item.exchange_hide_flag == 1 ? "是" : "否",
          item.exchange_store_name,
          this.formatAmount(item.store_exchange_detail_amount),
          `${item.store_exchange_archives_name} (${item.store_exchange_archives_no})`,
        ]),
      ];

      // 创建一个新的工作表
      const ws = XLSX.utils.aoa_to_sheet(wsData);

      // 创建一个新的工作簿并添加工作表
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, wsName);

      // 写入字符串
      const wbout = XLSX.write(wb, { bookType: "xlsx", type: "binary" });

      // 保存文件
      this.saveFile(new Blob([this.binaryStringToArrayBuffer(wbout)], { type: "application/octet-stream" }), {
        type: "application/octet-stream",
        headers: { "Content-Disposition": `attachment;filename=${wsName}.xlsx` },
      });
    },

    //    获取GetStore2StoreAccountFlowOutStorePay数据
    async getStoreAccountFlowOutStorePay() {
      const { data: res } = await this.$http.get("/StoreAccount/GetStore2StoreAccountFlowOutStorePay", {
        params: {
          st: this.formLabelAlign.st,
          et: this.formLabelAlign.et,
          storeID: this.formLabelAlign.storeId,
          currencyID: this.formLabelAlign.currencyID,
          differentStore: this.formLabelAlign.differentStore,
          pageIndex: this.storePaymentPageInfo.pageIndex,
          pageSize: this.storePaymentPageInfo.pageSize,
        },
      });
      if (res.code !== 1) return this.$message.error(res.message);
      this.storePaymentTableData = res.data;
      this.storePaymentPageInfo = res.pageInfo;
    },
    //   four表格分页
    handleStorePaymentTableChange(val) {
      this.storePaymentPageInfo.pageIndex = val;
      this.getStoreAccountFlowOutStorePay();
    },
    //  导出数据
    async storePaymentTableExport() {
      const { data: res } = await this.$http.get("/StoreAccount/GetStore2StoreAccountFlowOutStorePay", {
        params: {
          st: this.formLabelAlign.st,
          et: this.formLabelAlign.et,
          storeID: this.formLabelAlign.storeId,
          currencyID: this.formLabelAlign.currencyID,
          differentStore: this.formLabelAlign.differentStore,
          pageIndex: 1,
          pageSize: this.storePaymentPageInfo.total,
        },
      });
      if (res.code !== 1) return this.$message.error(res.message);
      let storePaymentData = res.data.filter((item) => item.exchange_hide_flag !== "1"); // 过滤掉需要隐藏的数据
      let date = moment().format("MM-DD HH:mm");

      const wsName = `${this.storeName}-tab4-${date}`;
      const wsData = [
        ["流水编号", "流水金额", "流水币种", "付款单号", "付款单店铺", "付款金额", "对手的交易单号", "对手的交易单店铺", "对手的交易单类型", "对手的交易单状态", "是否隐藏", "对手的交易单详情金额", "对手的交易单客户名字"],
        ...storePaymentData.map((item) => [
          item.store_account_flow_id,
          this.formatAmount(item.store_account_flow_amount),
          item.store_account_flow_currency_name,
          item.store_payment_no,
          item.store_payment_store_name,
          this.formatAmount(item.store_payment_amount),
          item.store_exchange_no,
          item.exchange_store_name,
          item.exchange_type_name,
          item.exchange_status_name,
          item.exchange_hide_flag == 1 ? "是" : "否",
          this.formatAmount(item.store_exchange_detail_amount),
          `${item.store_exchange_archives_name} (${item.store_exchange_archives_no})`,
        ]),
      ];

      // 创建一个新的工作表
      const ws = XLSX.utils.aoa_to_sheet(wsData);

      // 创建一个新的工作簿并添加工作表
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, wsName);

      // 写入字符串
      const wbout = XLSX.write(wb, { bookType: "xlsx", type: "binary" });

      // 保存文件
      this.saveFile(new Blob([this.binaryStringToArrayBuffer(wbout)], { type: "application/octet-stream" }), {
        type: "application/octet-stream",
        headers: { "Content-Disposition": `attachment;filename=${wsName}.xlsx` },
      });
    },
  },
};
</script>
<style lang="less" scoped></style>
