<template>
  <div class="customerAccountStatement">
    <!-- 面包屑导航区 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>统计</el-breadcrumb-item>
      <el-breadcrumb-item>往来记账</el-breadcrumb-item>
    </el-breadcrumb>
    <el-card class="box-card">
      <el-form label-width="80px" :model="formLabelAlign">
        <el-row>
          <el-col :span="5">
            <el-form-item label="开始时间:">
              <el-date-picker v-model="formLabelAlign.starTime" type="date" @change="handleDateChangeSt" placeholder="选择日期"> </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="5">
            <el-form-item label="结束时间:">
              <el-date-picker v-model="formLabelAlign.endTime" @change="handleDateChangeEt" type="date" placeholder="选择日期"> </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <el-form-item label="商家:">
              <el-select v-model="formLabelAlign.tenantPopover" @change="getStoreData" placeholder="请选择">
                <el-option v-for="(item, index) in tenantList" :key="index" :label="item.tenant_name" :value="item.tenant_id"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <el-form-item label="店铺:">
              <el-select @change="handleStoreChange" v-model="formLabelAlign.storeID" collapse-tags placeholder="请选择">
                <el-option v-for="item in storeList" :key="item.value" :label="item.store_name" :value="item.store_id"> </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <el-form-item label="选择币种:">
              <el-select clearable v-model="formLabelAlign.currencyID" placeholder="请选择">
                <el-option v-for="(item, index) in currencyList" :key="index" :label="item.currency_name" :value="item.currency_id"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="4">
            <el-form-item label="客户编号:">
              <el-input v-model="formLabelAlign.no" placeholder="请输入客户编号"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="3">
            <el-button type="primary" @click="search" icon="el-icon-search">搜索</el-button>
          </el-col>
        </el-row>
      </el-form>
    </el-card>
    <el-card class="box-card">
      <el-table :data="tableData1" style="width: 100%">
        <el-table-column prop="create_time" label="时间"></el-table-column>
        <el-table-column prop="currency_name" label="币种"></el-table-column>
        <el-table-column prop="amount" label="金额">
          <template slot-scope="scope">
            <span>{{ formatAmount(scope.row.amount) }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="balance" label="余额">
          <template slot-scope="scope">
            <span>{{ formatAmount(scope.row.balance) }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="remark" label="备注"></el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button @click="handleEdit(scope.$index, scope.row)">详情</el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-card>
  </div>
</template>
<script>
import moment from "moment";
export default {
  data() {
    return {
      formLabelAlign: {
        no: "",
        starTime: "",
        endTime: "",
        st: "", //开始时间
        et: "", //结束时间
        tenantPopover: "",
        storeID: "", //门店选择
        currencyID: "", //币种
        differentStore: false, //是否相同
      },

      tenantList: [], //商家列表
      storeList: [], //门店列表
      currencyList: [], //币种数据
      tableData1: [], //表格数据
    };
  },
  mounted() {
    this.getTenantList(); //获取商家列表
  },
  methods: {
    formatAmount(amount) {
      // 去除小数点前后多余的0
      amount = parseFloat(amount);

      if (isNaN(amount)) return "";
      var parts = String(amount).split(".");
      var integerPart = parts[0];
      var decimalPart = parts.length > 1 ? "." + parts[1] : "";

      // 每三位添加一个逗号
      integerPart = integerPart.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");

      return integerPart + decimalPart;
    },
    // 修改时间格式
    handleDateChangeSt(val) {
      if (val) {
        this.formLabelAlign.st = moment(val).format("YYYY-MM-DD") + " 00:00:00";
      }
    },
    handleDateChangeEt(val) {
      if (val) {
        this.formLabelAlign.et = moment(val).format("YYYY-MM-DD") + " 23:59:59";
      }
    },
    // 获取商家列表
    getTenantList() {
      const tenantsJson = localStorage.getItem("loginTenantsInfo");
      let tenants = [];
      if (tenantsJson) {
        tenants = JSON.parse(tenantsJson);
      }
      this.tenantList = tenants;
    },
    // 获取门店信息
    getStoreData() {
      this.storeID = "";
      this.$http
        .get("v1/Store/GetStoreInfoByTenant", {
          params: { tenant: this.formLabelAlign.tenantPopover },
        })
        .then(({ data: res }) => {
          this.storeList = res.data;
        });
    },
    // 店铺选择
    handleStoreChange(item) {
      this.$http
        .get("/StoreCurrency/GetEnable", {
          params: {
            store: item,
            tenant: this.formLabelAlign.tenantPopover,
          },
        })
        .then((res) => {
          if (res.code == -1) return this.$message.error(res.message);
          this.currencyList = res.data.data;
        });
    },
    // 搜索
    search() {
      this.$http
        .get("/api/CustomerAccount/GetFlow", {
          params: {
            st: this.formLabelAlign.st,
            et: this.formLabelAlign.et,
            storeId: this.formLabelAlign.storeID,
            currency: this.formLabelAlign.currencyID,
            CustomerID: this.formLabelAlign.no,
            orderByBusinessTime: false,
            pageIndex: 1,
            pageSize: 10,
          },
        })
        .then((res) => {
          if (res.data.code !== 1) return this.$message.error(res.message);
          this.tableData1 = res.data.data;
        });
    },
  },
};
</script>
<style lang="less" scoped></style>
