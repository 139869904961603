import { render, staticRenderFns } from "./transactionHistory.vue?vue&type=template&id=06f36c48&scoped=true"
import script from "./transactionHistory.vue?vue&type=script&lang=js"
export * from "./transactionHistory.vue?vue&type=script&lang=js"
import style0 from "./transactionHistory.vue?vue&type=style&index=0&id=06f36c48&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "06f36c48",
  null
  
)

export default component.exports