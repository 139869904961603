<template>
  <div class="container">
    <!-- 面包屑导航区 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>商户管理</el-breadcrumb-item>
      <el-breadcrumb-item>店铺账户</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="content">
      <div class="search-box">
        <div class="search-item">
          <div>选择商家：</div>
          <el-popover placement="bottom" width="200" v-model="tenantPopover" trigger="click">
            <div class="popover-content">
              <div v-for="(item, index) in tenantList" :key="index" @click="handleTenantChange(item)">
                {{ item.tenant_name }}
              </div>
            </div>
            <el-button slot="reference">{{ tenantInfo.tenant_name }}</el-button>
          </el-popover>
        </div>
        <div class="search-item1">
          <div class="selectStore">选择店铺：</div>
          <div class="popover-content1">
            <div class="store-list">
              <div v-for="(item, index) in storeList" :key="index" :class="{ 'red-background': index === activeIndex }" @click="handleStoreChange(item, index)">
                {{ item.store_name }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div style="width: 100%">
        <el-form label-width="80px">
          <el-row>
            <el-col :span="6">
              <el-form-item label="开始时间:">
                <el-date-picker @change="getAccountBalance" v-model="starTime" type="date" placeholder="选择日期"> </el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="结束时间:">
                <el-date-picker @change="getAccountBalance" v-model="endTime" type="date" placeholder="选择日期"> </el-date-picker>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <div class="account-balance-box">
        <el-tabs v-model="activeTabs" class="tabs-container">
          <el-tab-pane label="1001" name="Legal">
            <div class="currency-box">
              <div class="currency-li row" @click="changeCurrency(item)" v-for="(item, index) in cashBalanceList" :key="'cashBalanceList' + index">
                <div class="currency-img">
                  <img style="width: 60px; height: 60px" :src="getImgUrl(item.currency_code)" :alt="item.currency_code" />
                </div>
                <div class="currency-right">
                  <div class="currency-title row">
                    <el-tooltip effect="dark" :content="item.currency_name" placement="top">
                      <div class="currency-text row">
                        <div>{{ item.currency_code }}</div>
                        <div>{{ item.currency_name }}</div>
                      </div>
                    </el-tooltip>
                    <div class="currency-balance row">
                      <div>余额：</div>
                      <div
                        :style="{
                          fontSize: '22px',
                          fontWeight: '700',
                          color: getBalanceColor(item.totalBalance),
                        }">
                        {{ Number(item.totalBalance).toLocaleString() }}
                      </div>
                    </div>
                  </div>
                  <div class="currency-name row" v-if="item.availableBalance != 0">
                    <div>过数余额：</div>
                    <div
                      :style="{
                        fontSize: '20px',
                        fontWeight: '700',
                        color: getBalanceColor(item.availableBalance),
                      }">
                      {{ Number(item.availableBalance).toLocaleString() }}
                    </div>
                  </div>
                  <el-divider></el-divider>

                  <div class="currency-name row">
                    <div>买入金额：</div>
                    <div
                      :style="{
                        fontSize: '22px',
                        fontWeight: '700',
                        color: getBalanceColor(item.totalBuyAmount),
                      }">
                      {{ Number(parseFloat(item.totalBuyAmount) || 0).toLocaleString() }}
                    </div>
                  </div>
                  <div class="currency-name row">
                    <div>卖出金额：</div>
                    <div
                      :style="{
                        fontSize: '22px',
                        fontWeight: '700',
                        color: getBalanceColor(item.totalSaleAmount),
                      }">
                      {{ Number(parseFloat(item.totalSaleAmount) || 0).toLocaleString() }}
                    </div>
                  </div>
                  <div class="currency-name row">
                    <div>差值：</div>
                    <div
                      :style="{
                        fontSize: '22px',
                        fontWeight: '700',
                        color: getBalanceColor(item.totalAmount),
                      }">
                      {{ Number(parseFloat(item.totalAmount) || 0).toLocaleString() }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="currency-box">
              <div class="currency-li row" @click="changeCurrency(item)" v-for="(item, index) in balanceList" :key="'balanceList' + index">
                <div class="currency-img">
                  <img style="width: 60px; height: 60px" :src="getImgUrl(item.currency_code)" :alt="item.currency_code" />
                </div>
                <div class="currency-right">
                  <div class="currency-title row">
                    <el-tooltip effect="dark" :content="item.currency_name" placement="top">
                      <div class="currency-text row">
                        <div>{{ item.currency_code }}</div>
                        <div>{{ item.currency_name }}</div>
                      </div>
                    </el-tooltip>
                    <div class="currency-balance row">
                      <div>余额：</div>
                      <div
                        :style="{
                          fontSize: '22px',
                          fontWeight: '700',
                          color: getBalanceColor(item.totalBalance),
                        }">
                        {{ Number(item.totalBalance).toLocaleString() }}
                      </div>
                    </div>
                  </div>
                  <div class="currency-name row" v-if="item.availableBalance != 0">
                    <div>过数余额：</div>
                    <div
                      :style="{
                        fontSize: '20px',
                        fontWeight: '700',
                        color: getBalanceColor(item.availableBalance),
                      }">
                      {{ Number(item.availableBalance).toLocaleString() }}
                    </div>
                  </div>
                  <el-divider></el-divider>

                  <div class="currency-name row">
                    <div>买入金额：</div>
                    <div
                      :style="{
                        fontSize: '22px',
                        fontWeight: '700',
                        color: getBalanceColor(item.totalBuyAmount),
                      }">
                      {{ Number(parseFloat(item.totalBuyAmount) || 0).toLocaleString() }}
                    </div>
                  </div>
                  <div class="currency-name row">
                    <div>卖出金额：</div>
                    <div
                      :style="{
                        fontSize: '22px',
                        fontWeight: '700',
                        color: getBalanceColor(item.totalSaleAmount),
                      }">
                      {{ Number(parseFloat(item.totalSaleAmount) || 0).toLocaleString() }}
                    </div>
                  </div>
                  <div class="currency-name row">
                    <div>差值：</div>
                    <div
                      :style="{
                        fontSize: '22px',
                        fontWeight: '700',
                        color: getBalanceColor(item.totalAmount),
                      }">
                      {{ Number(parseFloat(item.totalAmount) || 0).toLocaleString() }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </el-tab-pane>
          <el-tab-pane label="1009" name="Virtual">
            <div class="currency-box">
              <div class="currency-li row" @click="changeCurrency(item)" v-for="(item, index) in bridgeBalanceList" :key="'bridgeBalanceList' + index">
                <div class="currency-img">
                  <img style="width: 60px; height: 60px" :src="getImgUrl(item.currency_code)" :alt="item.currency_code" />
                </div>
                <div class="currency-right">
                  <div class="currency-name row">
                    <div>{{ item.currency_code }}</div>
                    <div>{{ item.currency_name }}</div>
                  </div>
                  <div class="currency-name row">
                    <div>余额：</div>
                    <div
                      :style="{
                        fontSize: '22px',
                        fontWeight: '700',
                        color: getBalanceColor(item.balance),
                      }">
                      {{ Number(item.balance).toLocaleString() }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </el-tab-pane>
          <el-tab-pane label="1008" name="three">
            <div class="currency-box">
              <div class="currency-li row" @click="changeCurrency(item)" v-for="(item, index) in centerBalance" :key="'centerBalance' + index">
                <div class="currency-img">
                  <img style="width: 60px; height: 60px" :src="getImgUrl(item.currency_code)" :alt="item.currency_code" />
                </div>
                <div class="currency-right">
                  <div class="currency-name row">
                    <div>{{ item.currency_code }}</div>
                    <div>{{ item.currency_name }}</div>
                  </div>
                  <div class="currency-name row">
                    <div>余额：</div>
                    <div
                      :style="{
                        fontSize: '22px',
                        fontWeight: '700',
                        color: getBalanceColor(item.balance),
                      }">
                      {{ Number(item.balance).toLocaleString() }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
export default {
  components: {},
  watch: {},
  data() {
    return {
      activeIndex: 0,
      activeTabs: "Legal",
      tenantPopover: false,
      tenantList: [], // 商家列表
      tenantInfo: {}, // 商家信息
      storeList: [], // 店铺列表
      storeData: [], // 店铺列表
      storeInfo: {}, // 店铺信息
      balanceList: [], // 1001账户列表
      cashBalanceList: [], // 1001账户列表
      bridgeBalanceList: [], // 1009账户列表
      centerBalance: [], //1008账户余额列表
      starTime: moment().format("YYYY-MM-DD"),
      endTime: moment().format("YYYY-MM-DD"),
    };
  },
  onLoad() {},
  async mounted() {
    const loginTenantsInfo = localStorage.getItem("loginTenantsInfo");
    if (loginTenantsInfo) {
      try {
        this.tenantList = loginTenantsInfo ? JSON.parse(loginTenantsInfo) : [];
        this.tenantInfo = this.tenantList.length > 0 ? this.tenantList[0] : { tenant_name: "全部", tenant_id: "" };
      } catch (error) {
        this.tenantList = [];
        this.tenantInfo = { tenant_name: "全部", tenant_id: "" };
      }
      this.getStoreList();
    } else {
      const { data: res } = await this.$http.get("User");
      if (res.code == -1) return this.$message.error(res.message);
      // 假设 res.data.tenants 是从服务器获取的数据
      const tenantsJson = JSON.stringify(res.data.tenants);
      // 存储到本地存储中
      localStorage.setItem("loginTenantsInfo", tenantsJson);
      this.tenantList = res.data.tenants;
      this.tenantInfo = this.tenantList.length > 0 ? this.tenantList[0] : { tenant_name: "全部", tenant_id: "" };
      this.getStoreList();
    }
  },
  onShow() {},
  methods: {
    changeCurrency(item) {
      this.$router.push({
        path: "/home/balanceList",
        query: {
          currency: item.currency_code,
          tenant: this.tenantInfo.tenant_id,
          store: this.storeInfo.store_id,
        },
      });
    },
    /**
     * 根据账户余额返回颜色值
     *
     * @param balance 账户余额
     * @returns 如果余额大于等于0，返回'green'；否则返回'red'
     */
    getBalanceColor(balance) {
      const numBalance = Number(balance);
      return numBalance >= 0 ? "green" : "red";
    },
    getImgUrl(currencyCode) {
      try {
        return require(`@/assets/currency/${currencyCode}.png`);
      } catch (error) {
        console.error(`Image not found for currency code: ${currencyCode}`, error);
        return ""; // 默认图片
      }
    },
    // 商家选择
    handleTenantChange(item) {
      this.tenantInfo = item;
      this.getStoreList();
      this.tenantPopover = false;
    },
    handleStoreChange(item, index) {
      this.storeData = [];
      this.activeIndex = index;
      this.storeInfo = item;
      if (item.store_name == "全部" && item.store_id == "") {
        this.storeList.forEach((p) => {
          if (p.store_name != "全部" && p.store_id != "") {
            this.storeData.push(p.store_id);
          }
        });
      } else {
        this.storeData.push(item.store_id);
      }
      this.getAccountBalance();
    },
    // 获取商家店铺列表
    async getStoreList() {
      const { data: res } = await this.$http.get("v1/Store/GetStoreInfoByTenant", {
        params: { tenant: this.tenantInfo.tenant_id },
      });
      if (res.code == -1) return this.$message.error(res.message);
      res.data.unshift({
        store_id: "",
        store_name: "全部",
      });
      let storeId = ["608322287283867648", "629155004615364608", "644089413139304448", "644089484933206016"];
      this.storeList = res.data.filter((item) => !item.store_name.includes("停用") && !item.store_name.includes(storeId));
      this.storeInfo = res.data[0];
      this.storeList.forEach((item) => {
        if (item.store_name != "全部" && item.store_id != "") {
          this.storeData.push(item.store_id);
        }
      });
      this.getAccountBalance();
    },
    // 获取账户余额
    async getAccountBalance() {
      try {
        const { data: res } = await this.$http.post("StoreAccount/GetStoreAccountBalanceSumNew", this.storeData);
        if (res.code == -1) return this.$message.error(res.message);
        res.cash_balance.forEach((item) => {
          item.availableBalance = 0;
          item.totalBalance = item.balance;
        });
        this.bridgeBalanceList = res.bridge_balance;
        this.centerBalance = res.center_balance;
        this.getTempBalance(res.cash_balance);
      } catch (error) {
        console.error("Error fetching account balance:", error);
        this.$message.error("获取账户余额失败，请稍后再试。");
      }
    },
    // 获取账户余额
    async getTempBalance(cashBalance) {
      const startTime = moment(this.starTime);
      const endTime = moment(this.endTime);
      const newStartTime = startTime.format("YYYY-MM-DD") + " 00:00:00";
      const newEndTime = endTime.format("YYYY-MM-DD") + " 23:59:59";
      if (this.storeInfo.store_id == "" && this.storeInfo.store_name == "全部") {
        // 把 this.storeList 转换为数组，并过滤掉 store_name 为 "全部" 和 store_id 为 "" 的项，然后转换为数组
        let storeList = this.storeList.filter((item) => item.store_name !== "全部" && item.store_id !== "");
        storeList = storeList.map((item) => item.store_id);
        let tempBalance = [];
        let tempBalanceList = [];
        try {
          const { data: response } = await this.$http.post("api/Report/Report3", {
            storeList: storeList,
            st: newStartTime,
            et: newEndTime,
          });
          if (response.data && Array.isArray(response.data) && response.data.length > 0) {
            cashBalance.forEach((item) => {
              item.totalBuyAmount = 0;
              item.totalSaleAmount = 0;
              item.totalAmount = 0;
              if (response.data && response.data.length > 0) {
                response.data.forEach((cItem) => {
                  if (item.currency_code == cItem.currency_code) {
                    item.totalBuyAmount = Number(cItem.total_buy_amount);
                    item.totalSaleAmount = Number(cItem.total_sale_amount);
                    item.totalAmount = Number(cItem.total_buy_amount) - Number(cItem.total_sale_amount);
                  }
                });
              }
            });
          }
        } catch (error) {
          console.error("Error fetching temp balance:", error);
        }
        try {
          const { data: res } = await this.$http.post("StoreAccount/GetTempBalanceNew", storeList);
          if (res.code == -1) return this.$message.error(res.message);
          // 验证 res.data 是否为数组
          tempBalance = res.data || [];
        } catch (error) {
          console.error("Error fetching temp balance:", error);
        }
        // 合并相同币种的余额
        tempBalanceList = tempBalance.reduce((acc, balance) => {
          const existingBalance = acc.find((item) => item.currencyCode === balance.currencyCode);
          if (existingBalance) {
            existingBalance.availableBalance += Number(balance.availableBalance) || 0;
          } else {
            balance.availableBalance = Number(balance.availableBalance) || 0; // 确保 availableBalance 是数字
            acc.push({ ...balance });
          }
          return acc;
        }, []);
        cashBalance.forEach((item) => {
          item.totalBalance = 0;
          item.availableBalance = 0;
          tempBalanceList.forEach((cItem) => {
            if (item.balance != "" && cItem.availableBalance != "") {
              if (item.currency_code == cItem.currencyCode) {
                item.availableBalance = Number(cItem.availableBalance);
                item.totalBalance = Number(item.balance) - Number(cItem.availableBalance);
              }
            }
          });
        });
        let cashList = cashBalance.map((item) => ({
          ...item,
        }));
        this.balanceList = [];
        this.cashBalanceList = [];
        cashList.forEach((item) => {
          if (item.availableBalance != 0) {
            this.cashBalanceList.push(item);
          } else {
            this.balanceList.push(item);
          }
        });
      } else {
        try {
          const { data: response } = await this.$http.post("api/Report/Report3", {
            storeList: [this.storeInfo.store_id],
            st: newStartTime,
            et: newEndTime,
          });
          cashBalance.forEach((item) => {
            item.totalBuyAmount = 0;
            item.totalSaleAmount = 0;
            item.totalAmount = 0;
            if (response.data && response.data.length > 0) {
              response.data.forEach((cItem) => {
                if (item.currency_code == cItem.currency_code) {
                  item.totalBuyAmount = Number(cItem.total_buy_amount);
                  item.totalSaleAmount = Number(cItem.total_sale_amount);
                  item.totalAmount = Number(cItem.total_buy_amount) - Number(cItem.total_sale_amount);
                }
              });
            }
          });
        } catch (error) {
          console.error("Error fetching temp balance:", error);
        }
        try {
          const { data: res } = await this.$http.get("StoreAccount/GetTempBalance", {
            params: {
              store: this.storeInfo.store_id,
            },
          });
          if (res.code == -1) return this.$message.error(res.message);
          // 验证 res.data 是否为数组
          if (res.data && Array.isArray(res.data) && res.data.length > 0) {
            res.data.forEach((cItem) => {
              cashBalance.forEach((item) => {
                // 将空字符串视为0进行处理
                let itemBalance = parseFloat(item.balance) || 0;
                let cItemAvailableBalance = parseFloat(cItem.availableBalance) || 0;
                // 判断 item.currency_code 和 cItem.currencyCode 是否相等
                if (item.currency_code === cItem.currencyCode) {
                  item.availableBalance = cItemAvailableBalance;
                  item.totalBalance = itemBalance - cItemAvailableBalance;
                }
              });
            });
            this.balanceList = [];
            this.cashBalanceList = [];
            cashBalance.forEach((item) => {
              if (item.availableBalance != 0) {
                this.cashBalanceList.push(item);
              } else {
                this.balanceList.push(item);
              }
            });
          }
        } catch (error) {
          console.error("Error fetching temp balance:", error);
        }
      }
    },
  },
};
</script>

<style lang="less" scoped>
.red-background {
  background-color: red;
}
.container {
  width: 100%;
  height: 100%;
  .content {
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    .search-box {
      width: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      .search-item {
        display: flex;
        align-items: center;
        margin-right: 30px;
        > :nth-child(1) {
          width: 80px;
        }
      }
      .search-item1 {
        width: calc(100% - 100px);
        display: flex;
        align-items: center;
        .selectStore {
          width: 80px;
        }
      }
    }
    .account-balance-box {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
      /deep/.el-tabs__nav-scroll {
        padding: 10px 0;
      }
      .tabs-container {
        width: 100%;
        height: 100%;
        /deep/.el-tabs__item {
          font-size: 16px;
        }
        .currency-box {
          padding: 20px 5px;
          box-sizing: border-box;
          overflow: auto;
          width: 100%;
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          justify-content: flex-start;
          .currency-li {
            width: 19.4%;
            margin: 10px 5px 0;
            cursor: pointer;
            display: flex;
            border: 1px solid #000;
            align-items: center;
            box-sizing: border-box;
            .currency-img {
              height: 100%;
              padding: 20px 10px;
              margin: 0;
              display: flex;
              align-self: start;
            }
            .currency-right {
              width: calc(100% - 80px);
              .currency-title {
                align-items: center;
                justify-content: space-between;
                .currency-text {
                  > :nth-child(2) {
                    width: 50px;
                    white-space: nowrap; // 防止文本换行
                    overflow: hidden; // 隐藏溢出的文本
                    text-overflow: ellipsis; // 显示省略号
                  }
                }
                .currency-balance {
                  padding: 5px 10px 5px 0;
                  align-items: center;
                  > div {
                    height: 30px;
                    line-height: 30px;
                    font-size: 16px;
                  }
                }
              }
              .el-divider {
                margin: 10px 0;
              }
              .currency-name {
                padding: 5px 0;
                align-items: center;
                > div {
                  height: 30px;
                  line-height: 30px;
                  font-size: 16px;
                }
              }
            }
          }
        }
      }
    }
  }
}
.popover-content {
  width: 100%;
  max-height: 600px;
  overflow: auto;
  padding: 20px 10px;
  box-sizing: border-box;

  > div {
    width: 90%;
    height: 40px;
    box-sizing: border-box;
    cursor: pointer;
    font-size: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 10px;
    box-shadow: 0px 0px 5px 0px rgb(135, 135, 135);
  }
}
.popover-content1 {
  width: calc(100% - 80px);
  overflow-x: auto;
  padding: 10px 0;
  box-sizing: border-box;
  .store-list {
    width: 100%;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    > div {
      cursor: pointer; // 添加小手样式
      min-width: 100px;
      white-space: nowrap;
      height: 40px;
      padding: 0 10px;
      box-sizing: border-box;
      font-size: 18px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 10px;
      box-shadow: 0px 0px 5px 0px rgb(135, 135, 135);
    }
  }
}
.el-form {
  padding: 10px 0;
  /deep/.el-form-item {
    margin: 0 !important;
  }
}
</style>
