<template>
  <div class="component-container">
    <div class="iconScroll_row">
      <div class="form-atta row iconScroll">
        <div class="iconScroll-top">
          <div class="atta-scroll" :style="{ overflowX: 'auto' }">
            <div class="upload-image row">
              <div class="image-li" v-for="(item, index) in uploadPathList" :key="index">
                <div class="icon" @click="previewImage(item)" v-if="item.state === 1">
                  <el-image style="width: 100px; height: 100px" :src="item.path" :preview-src-list="srcList" class="show-image-cash"> </el-image>
                  <el-button type="warning" size="mini" @click="printImage(item)" icon="el-icon-edit">打印 </el-button>
                </div>
                <div class="icon" @click="retryDownload(item)" v-if="item.state === -1">
                  <div style="width: 100px; height: 100px; font-size: 14px">加载失败</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<!-- 
https://api.cash940.com/im-tenant/FileUpload/GetBase64?filekey=645505848361226240

https://api.cash940.com/im-tenant/FileUpload/GetBase64?filekey=54f0ffdd494e7bd992d351f1cd63c1b5

-->
<script>
export default {
  props: {
    uploaded: {
      type: Array, // 或 String，根据实际需求
      default: () => [], // 或 ''
    },
  },
  data() {
    return {
      uploadPathList: [], // 存储文件状态和路径
      srcList: [],
    };
  },
  watch: {
    uploaded: {
      immediate: true,
      handler(newVal) {
        // 判断 newVal 是否为数组，如果是则直接使用，否则使用其值组成的数组
        let fileList = Array.isArray(newVal) ? newVal : Object.values(newVal);

        // 遍历 fileList，为每个文件项创建一个包含 fileKey、state 和 path 的对象数组
        this.uploadPathList = fileList.map((fileKey) => ({
          // 文件键
          fileKey,

          // 文件状态，初始化为 0
          state: 0,

          // 文件路径，初始化为空字符串
          path: "",
        }));

        // 调用下载文件的方法
        this.downloadFiles();
      },
    },
  },
  methods: {
    // 打印图片的方法
    printImage(item) {
      const printWindow = window.open("", "", "width=600,height=400");

      // 创建 HTML 内容并插入到新窗口中
      const content = `
        <html>
          <head>
            <title>打印图片</title>
            <style>
              body {
                text-align: center;
                padding: 20px;
              }
              img {
                max-width: 100%;
                height: 100%;
              }
            </style>
          </head>
          
          <body>
            <img src="${item.path}" alt="打印图片" />
           
          </body>
        </html>
      
      `;

      // 将 HTML 内容写入到新打开的窗口
      printWindow.document.write(content);
      printWindow.document.close(); // 关闭文档流，确保内容被渲染
      // 确保窗口打印时显示完整内容
      this.$nextTick(() => {
        printWindow.focus(); // 聚焦窗口，确保内容被渲染
        printWindow.print(); // 打印内容
      });
    },

    async downloadFiles() {
      for (const item of this.uploadPathList) {
        try {
          const response = await this.$http.get("FileUpload/GetBase64", {
            params: { filekey: item.fileKey.file_key },
          });

          // 解析 base64 字符串并转换为 Blob 对象
          const binaryData = atob(response.data.replace(/^data:image\/\w+;base64,/, ""));
          const arrayBuffer = new Uint8Array(binaryData.length);
          for (let i = 0; i < binaryData.length; i++) {
            arrayBuffer[i] = binaryData.charCodeAt(i);
          }
          const blob = new Blob([arrayBuffer], { type: "image/jpeg" }); // 根据实际图片格式调整 MIME 类型

          // 生成一个 URL 来显示图片
          item.path = URL.createObjectURL(blob);
          item.state = 1;
        } catch (error) {
          console.error("Error downloading the file:", error);
          item.state = -1;
        }
      }
    },
    previewImage(item) {
      // 实现预览逻辑
      this.srcList.push(item.path);
    },

    retryDownload(item) {
      // 重新尝试下载
      this.downloadFile(item.fileKey)
        .then(() => {
          item.state = 1;
        })
        .catch(() => {
          item.state = -1;
        });
    },
  },
};
</script>

<style lang="less" scoped>
.component-container {
  width: 100%;
  height: 100%;
}

.iconScroll_row {
  width: 100%;
  height: 100%;
  .iconScroll {
    display: flex;
    align-items: center;
    height: 100%;
    width: 100%;
    .iconScroll-top {
      display: flex;
      align-items: center;
      width: 100%;
    }
    .form-name {
      width: unset;
      min-width: 50px;
      max-width: 100px;
      font-size: 16px;
    }
    .atta-scroll {
      width: calc(100% - 100px);
      padding-left: 0;
      height: 100%;
    }
  }
}

.upload-image {
  height: 100%;
  width: 100%;
  align-items: center;
  padding-top: 6rpx;

  .image-li {
    position: relative;
    margin-right: 10rpx;
    align-items: flex-end;
    .icon {
      view {
        display: flex;
        flex-direction: row-reverse;
      }
    }
    .image-icon {
      position: absolute;
      top: -6rpx;
      margin: auto;
      left: 0;
      right: 0;
      font-size: 36rpx;
      width: 36rpx;
    }
  }
}

.show-image-cash {
  width: 100px;
  height: 100px;
}
</style>
