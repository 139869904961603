<template>
  <div>
    <h1>{{ msg }}</h1>
    <h3>{{ storePopover }}</h3>
  </div>
</template>

<script>
export default {
  name: "HelloWorld",
  props: ["storePopover"],
  data() {
    return {
      msg: "Hello",
    };
  },
  mounted() {},
  methods: {
    sayHello() {
      alert("Hello");
    },
  },
};
</script>

<style scoped></style>
