<template>
  <div class="dataReport">
    <!-- 面包屑导航区 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>数据统计</el-breadcrumb-item>
      <el-breadcrumb-item>交易报告</el-breadcrumb-item>
    </el-breadcrumb>

    <div class="container">
      <div class="block">
        <el-card class="box-card">
          <div>
            <el-form label-width="80px" :model="formLabelAlign">
              <el-row>
                <el-col :span="6">
                  <el-form-item label="开始时间:">
                    <el-date-picker v-model="formLabelAlign.starTime" type="date" placeholder="选择日期"> </el-date-picker>
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item label="结束时间:">
                    <el-date-picker v-model="formLabelAlign.endTime" type="date" placeholder="选择日期"> </el-date-picker>
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item label="门店:">
                    <el-cascader
                      v-model="storeList"
                      :options="cascaderOptions"
                      :props="{
                        checkStrictly: false,
                        multiple: true,
                        emitPath: false,
                      }"
                      placeholder="请选择" />
                  </el-form-item>
                </el-col>
                <el-col :span="3">
                  <el-button type="primary" @click="getStatisticalTransactionReports" icon="el-icon-search">搜索</el-button>
                </el-col>
              </el-row>
            </el-form>
          </div>
        </el-card>
        <el-card class="box-card">
          <el-row class="demo-box">
            <el-col :span="12">
              <div style="height: 100%; overflow: hidden">
                <el-table :data="buySellListData" header-align="center" style="width: 100%">
                  <el-table-column label="买入" header-align="center">
                    <el-table-column prop="nickName"> </el-table-column>
                    <el-table-column label="款额(港币)" header-align="center" align="right" prop="purchaseAmount">
                      <template slot-scope="scope">
                        <span :style="{ color: getColor(scope.row.purchaseAmount) }">{{ formatAmount(scope.row.purchaseAmount) }}</span>
                      </template>
                    </el-table-column>
                    <el-table-column label="交易宗数" header-align="center" align="right" prop="purchaseTotal"> </el-table-column>
                  </el-table-column>
                  <el-table-column label="卖出" header-align="center">
                    <el-table-column label="款额(港币)" header-align="center" align="right" prop="sellingAmount">
                      <template slot-scope="scope">
                        <span :style="{ color: getColor(scope.row.sellingAmount) }">{{ formatAmount(scope.row.sellingAmount) }}</span>
                      </template>
                    </el-table-column>
                    <el-table-column label="交易宗数" header-align="center" align="right" prop="sellingTotal"> </el-table-column>
                  </el-table-column>
                </el-table>

                <el-table :data="buySellTransactionsTotalData" border header-align="center" style="width: 100%">
                  <el-table-column prop="nickName" width="120" header-align="center"> </el-table-column>
                  <el-table-column label="成功交易总数" header-align="center" align="right" prop="purchaseAmount"></el-table-column>
                  <el-table-column label="拒绝交易总数" header-align="center" align="right" prop="purchaseTotal"></el-table-column>
                </el-table>
              </div>
            </el-col>

            <el-col :span="12" style="background-color: brown">
              <div style="height: 100%; overflow: hidden">
                <el-table :data="importExportData" header-align="center" style="width: 100%">
                  <el-table-column label="汇出的汇款" header-align="center">
                    <el-table-column prop="nickName"> </el-table-column>
                    <el-table-column label="款额(港币)" header-align="center" align="right" prop="remittanceAmount">
                      <template slot-scope="scope">
                        <span :style="{ color: getColor(scope.row.remittanceAmount) }">{{ formatAmount(scope.row.remittanceAmount) }}</span>
                      </template>
                    </el-table-column>
                    <el-table-column label="交易宗数" header-align="center" align="right" prop="remittanceTotal"> </el-table-column>
                  </el-table-column>
                  <el-table-column label="汇入的汇款" header-align="center">
                    <el-table-column label="款额(港币)" header-align="center" align="right" prop="remitAmount">
                      <template slot-scope="scope">
                        <span :style="{ color: getColor(scope.row.remitAmount) }">{{ formatAmount(scope.row.remitAmount) }}</span>
                      </template>
                    </el-table-column>
                    <el-table-column label="交易宗数" header-align="center" align="right" prop="remitTotal"> </el-table-column>
                  </el-table-column>
                </el-table>
                <el-table :data="importExportTransactionsTotalData" border header-align="center" style="width: 100%">
                  <el-table-column label="成功交易总数" header-align="center" align="right" prop="faringTotal"></el-table-column>
                  <el-table-column label="拒绝交易总数" header-align="center" align="right" prop="purchaseTotal"></el-table-column>
                </el-table>
              </div>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <div style="margin-top: 20px">
                <el-table :data="cashRemittanceData" boder header-align="center" style="width: 100%">
                  <el-table-column label="现金对汇款" align="center">
                    <el-table-column prop="remittanceAccount" align="center"></el-table-column>
                    <el-table-column label="总额" prop="spotExchangeTotal" align="center">
                      <template slot-scope="scope">
                        <span :style="{ color: getColor(scope.row.spotExchangeTotal) }">
                          {{ formatAmount(scope.row.spotExchangeTotal) }}
                        </span>
                      </template> </el-table-column
                    >numberEntries
                    <el-table-column label="笔数" prop="numberEntries" align="center"></el-table-column>
                  </el-table-column>
                </el-table>
              </div>
            </el-col>
            <el-col :span="12">
              <div style="margin-top: 20px">
                <el-table :data="cashAmountSummaryData" boder header-align="center" style="width: 100%">
                  <el-table-column label="现金对现金-买入卖出金额汇总" align="center">
                    <el-table-column label="买入金额汇总" prop="total_buy_amount" align="center">
                      <template slot-scope="scope">
                        <span :style="{ color: getColor(scope.row.total_buy_amount) }"> {{ formatAmount(scope.row.total_buy_amount) }}</span>
                      </template>
                    </el-table-column>
                    <el-table-column label="币种" prop="currency_name" align="center"></el-table-column>
                    <el-table-column label="卖出金额汇总" prop="total_sale_amount" align="center">
                      <template slot-scope="scope">
                        <span :style="{ color: getColor(scope.row.total_sale_amount) }">
                          {{ formatAmount(scope.row.total_sale_amount) }}
                        </span>
                      </template>
                    </el-table-column>
                    <el-table-column label="买入减卖出" align="center">
                      <template slot-scope="scope">
                        <span :style="{ color: getColor((Number(scope.row.total_buy_amount) - Number(scope.row.total_sale_amount)).toFixed(2)) }"> {{ formatAmount((Number(scope.row.total_buy_amount) - Number(scope.row.total_sale_amount)).toFixed(2)) }}</span>
                      </template>
                    </el-table-column>
                  </el-table-column>
                </el-table>
              </div>
            </el-col>
          </el-row>
        </el-card>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
export default {
  components: {},
  data() {
    return {
      buySellListData: [
        {
          nickName: "人民币",
        },
        {
          nickName: "其他货币",
        },
        {
          nickName: "总数",
        },
      ],
      buySellTransactionsTotalData: [
        {
          nickName: "人民币",
        },
        {
          nickName: "其他货币",
        },
        {
          nickName: "总数",
        },
      ],
      importExportData: [
        {
          nickName: "其他金钱服务经营者",
        },
        {
          nickName: "个别人士",
        },
        {
          nickName: "其他",
        },
        {
          nickName: "总数",
        },
      ],
      importExportTransactionsTotalData: [],
      cashRemittanceData: [
        {
          remittanceAccount: "微信",
          spotExchangeTotal: "",
          numberEntries: "",
        },
        {
          remittanceAccount: "支付宝",
          spotExchangeTotal: "",
          numberEntries: "",
        },
        {
          remittanceAccount: "银行卡",
          spotExchangeTotal: "",
          numberEntries: "",
        },
      ],
      cashAmountSummaryData: [],
      formLabelAlign: {
        store: "",
        daterangeTime: [],
        contents: "",
        starTime: this.getThreeMonthsAgo(),
        endTime: this.getCurrentTime(),
      },

      cascaderOptions: [],

      storeList: [],
    };
  },
  mounted() {
    this.formLabelAlign.starTime = this.getThreeMonthsAgo();
    this.formLabelAlign.endTime = this.getCurrentTime();
    this.getTenant();
  },
  methods: {
    // 整数时显示红色，负数时显示绿色
    getColor(amount) {
      return amount >= 0 ? "green" : "red";
    },
    getTenant() {
      this.$http.get("/api/Tenant").then((res) => {
        // 转换为 el-cascader 的数据结构
        const cascaderData = res.data.data.map((tenant) => ({
          value: tenant.tenant_id,
          label: tenant.tenant_name,

          children: tenant.stores.map((store) => ({
            value: store.store_id,
            label: store.store_name,
          })),
        }));
        this.cascaderOptions = cascaderData;
      });
    },
    getCurrentTime() {
      const currentDate = moment();
      return currentDate.format("YYYY-MM-DD");
    },
    getThreeMonthsAgo() {
      const currentDate = moment();
      currentDate.subtract(3, "months");

      return currentDate.format("YYYY-MM-DD");
    },

    //金额格式化
    formatAmount(amount) {
      // 去除小数点前后多余的0
      amount = parseFloat(amount);
      if (isNaN(amount)) return "";
      var parts = String(amount).split(".");
      var integerPart = parts[0];
      var decimalPart = parts.length > 1 ? "." + parts[1] : "";

      // 每三位添加一个逗号
      integerPart = integerPart.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");

      return integerPart + decimalPart;
    },
    getStatisticalTransactionReports() {
      const startTime = moment(this.formLabelAlign.starTime);
      const endTime = moment(this.formLabelAlign.endTime);
      const newStartTime = startTime.format("YYYY-MM-DD") + " 00:00:00";
      const newEndTime = endTime.format("YYYY-MM-DD") + " 23:59:59";
      this.$http
        .post("api/Report/Report", {
          st: newStartTime,
          et: newEndTime,
          storeList: this.storeList,
        })
        .then(({ data: res }) => {
          if (res.code == -1) return this.$message.error(res.message);
          const countData = res.data;
          let buySellList = [
            {
              nickName: "人民币",
              purchaseAmount: countData.cash2Cash_CNY2HKD_HKDSum,
              purchaseTotal: countData.cash2Cash_CNY2HKD_Count,
              sellingAmount: countData.cash2Cash_HKD2CNY_HKDSum,
              sellingTotal: countData.cash2Cash_HKD2CNY_Count,
            },
            {
              nickName: "其他货币",
              purchaseAmount: countData.cash2Cash_Other2HKD_HKDSum,
              purchaseTotal: countData.cash2Cash_Other2HKD_Count,
              sellingAmount: countData.cash2Cash_HKD2Other_HKDSum,
              sellingTotal: countData.cash2Cash_HKD2Other_Count,
            },
            {
              nickName: "总数",
              purchaseAmount: countData.cash2Cash_CNY2HKD_HKDSum + countData.cash2Cash_Other2HKD_HKDSum,
              purchaseTotal: countData.cash2Cash_CNY2HKD_Count + countData.cash2Cash_Other2HKD_Count,
              sellingAmount: countData.cash2Cash_HKD2CNY_HKDSum + countData.cash2Cash_HKD2Other_HKDSum,
              sellingTotal: countData.cash2Cash_HKD2CNY_Count + countData.cash2Cash_HKD2Other_Count,
            },
          ];
          this.buySellListData = buySellList;
          let buySellTransactionsTotal = [
            {
              nickName: "人民币",
              purchaseAmount: countData.cash2Cash_CNY2HKD_Count_12 + countData.cash2Cash_HKD2CNY_Count_12,
              purchaseTotal: "",
            },
            {
              nickName: "其他货币",
              purchaseAmount: countData.cash2Cash_Other2HKD_Count_12 + countData.cash2Cash_HKD2Other_Count_12,
              purchaseTotal: "",
            },
            {
              nickName: "总数",
              purchaseAmount: countData.cash2Cash_CNY2HKD_Count_12 + countData.cash2Cash_HKD2CNY_Count_12 + countData.cash2Cash_Other2HKD_Count_12 + countData.cash2Cash_HKD2Other_Count_12,
              purchaseTotal: "",
            },
          ];
          this.buySellTransactionsTotalData = buySellTransactionsTotal;
          // 处理金额
          let inputNum = parseFloat(countData.cash2Trans_All2CNY_CNYSum * 1.098901099);
          let inputNum2 = parseFloat(countData.surplus2Cash_CNY2All_CNYSum * 1.098901099);
          let roundedInputNum = Math.round(inputNum);
          let roundedInputNum2 = Math.round(inputNum2);
          let importExport = [
            {
              nickName: "其他金钱服务经营者",
            },
            {
              nickName: "个别人士",
              remittanceAmount: roundedInputNum, //1
              remittanceTotal: countData.cash2Trans_All2CNY_Count, //2
              remitAmount: roundedInputNum2, //3
              remitTotal: countData.surplus2Cash_CNY2All_Count, //4
            },
            {
              nickName: "其他",
            },
            {
              nickName: "总数",
            },
          ];
          this.importExportData = importExport;
          this.importExportTransactionsTotalData = [
            {
              faringTotal: countData.cash2Trans_All2CNY_Count_8 + countData.surplus2Cash_CNY2All_Count_8,
            },
          ];
        })
        .then(() => {
          this.$http
            .post("api/Report/Report2", {
              st: newStartTime,
              et: newEndTime,
              storeList: this.storeList,
            })
            .then(({ data: res }) => {
              if (res.code !== 1) return this.$message.error(res.message);
              let cashRemittance = res.data;
              this.cashRemittanceData = [
                {
                  remittanceAccount: "微信",
                  spotExchangeTotal: cashRemittance.cash2Trans_WX_SaleAmout_Sum,

                  numberEntries: cashRemittance.cash2Trans_WX_Count,
                },
                {
                  remittanceAccount: "支付宝",
                  spotExchangeTotal: cashRemittance.cash2Trans_Alipay_SaleAmout_Sum,
                  numberEntries: cashRemittance.cash2Trans_Alipay_Count,
                },
                {
                  remittanceAccount: "银行卡",
                  spotExchangeTotal: cashRemittance.cash2Trans_BankCard_SaleAmout_Sum,
                  numberEntries: cashRemittance.cash2Trans_BankCard_Count,
                },
              ];
            });
        })
        .then(() => {
          this.$http
            .post("api/Report/Report3", {
              st: newStartTime,
              et: newEndTime,
              storeList: this.storeList,
            })
            .then(({ data: res }) => {
              if (res.code !== 1) return this.$message.error(res.message);
              this.cashAmountSummaryData = res.data;
            });
        });
    },
  },
};
</script>

<style lang="less" scoped>
.dataReport {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
}

.el-table {
  margin-top: 0;
  font-size: 12px;
}

/deep/ .el-range-separator {
  padding: 0px !important;
}
</style>
