<template>
  <div class="listOfIoTDevices">
    <div class="header">
      <div
        class="title"
        v-for="item in deviceOptions"
        :key="item.value"
        @click="
          selectedType = item.value;
          filterData();
        ">
        <div :style="selectedType == item.value ? 'background-color: #21a2f1;color: #fff' : ''">{{ item.label }}</div>
      </div>
      <div class="button">
        <el-button type="primary" @click="_getSheBeiData">刷新</el-button>
      </div>
    </div>
    <div class="deviceList">
      <div v-for="item in filteredData" :key="item.id" class="device">
        <div class="deviceItem" :style="item.onlineState == 'online' ? '' : 'background-color: red;color: #fff'">
          <div>
            <div>设备名称</div>
            <div>{{ item.name }}</div>
          </div>
          <div>
            <div>类型</div>
            <div>{{ getTypeDisplay(item.type) }}</div>
          </div>
          <div>
            <div>是否在线</div>
            <div>{{ getTypeDisplay(item.onlineState) }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";

export default {
  data() {
    return {
      sheBeiData: [], // 原-设备数据列表
      // 筛选后的数据
      filteredData: [],
      // 设备类型选项
      deviceOptions: [
        { value: "", label: "全部" },
        { value: "GRGBanking", label: "TRC 设备" },
        { value: "GRGPassport", label: "护照机设备" },
        { value: "Dowjones", label: "道琼斯设备" },
      ],
      // 当前选择的设备类型
      selectedType: "",
    };
  },
  mounted() {
    this._getSheBeiData();
  },
  methods: {
    getTypeDisplay(type) {
      switch (type) {
        case "GRGBanking":
          return "TRC 设备";
        case "GRGPassport":
          return "护照机设备";
        case "Dowjones":
          return "道琼斯设备";
        case "online":
          return "在线";
        case "offline":
          return "离线";
        default:
          return "未知设备";
      }
    },
    _getSheBeiData() {
      axios.get("https://nxapi.seek940.com/openapi/api/open/getdevicelist").then((res) => {
        if (res.status == 200 && res.data.code == 0) {
          this.sheBeiData = res.data.data;
          this.filteredData = this.sheBeiData; // 初始化筛选后的数据为全部设备列表
        } else {
          this.$message.error("获取设备列表失败");
        }
      });
    },
    // 筛选数据
    filterData() {
      if (this.selectedType) {
        this.filteredData = this.sheBeiData.filter((item) => item.type == this.selectedType);
      } else {
        this.filteredData = this.sheBeiData;
      }
    },
    // 看设备是否离线，离线给改变行颜色
    tableRowClassName({ row }) {
      return row.onlineState == "offline" ? "row-offline" : "";
    },
  },
};
</script>
<style lang="less" scoped>
.listOfIoTDevices {
  .header {
    display: flex;
    align-items: center;
    .title {
      display: flex;
      align-items: center;
      > div {
        padding: 10px;
        margin: 0 10px;
        cursor: pointer; // 添加小手样式
        background-color: #fff;
      }
    }
    .button {
      margin-left: 20px;
    }
  }
  .deviceList {
    margin-top: 20px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    .device {
      width: 20%;
      padding: 10px;
      .deviceItem {
        border-radius: 10px;
        background-color: #fff;
        > div {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 10px;
        }
      }
    }
  }
}
</style>
