<!-- 
  交易中心账户统计
  2024-3-22
  xzl -->
<template>
  <div>
    <div class="search">
      <div>
        <el-input placeholder="请输入用户名称" v-model="queryInfo.userName" clearable @clear="query">
          <el-button slot="append" icon="el-icon-search" @click="query"></el-button>
        </el-input>
        <el-checkbox v-model="checked">显示全部账户</el-checkbox>
      </div>
    </div>
    <div>
      <br />
      资产-负债：
      <span v-if="allZero == true">0</span>
      <span v-else>
        <span v-for="item in cha" :key="item.currencyCode"> {{ item.c }}{{ item.currencyCode }}&nbsp;&nbsp; </span>
      </span>
    </div>
    <div v-for="item in ClassData" :key="item.totalTypeCode">
      <br />
      <fieldset class="box">
        <legend class="legend">
          {{ item.typeName[0].V }}：
          <span v-for="sum in item.balancesSum" :key="sum.currencyCode">
            <span v-if="parseFloat(sum.availableBalanceSum) != 0"> {{ formatAmount(sum.availableBalanceSum) }}{{ sum.currencyCode }} </span>
          </span>
        </legend>

        <div v-for="accountItem in item.tradeCenterAccounts" :key="accountItem.accountId" class="accountBox">
          <!-- 显示全部账户 -->
          <span v-if="checked">
            <span v-if="accountItem.balances.length > 0">
              {{ accountItem.name }} (<span style="color: blue">{{ accountItem.userName }} </span> )
            </span>
            <span>固定额度：</span>
            <span v-for="subItem in accountItem.balances" :key="subItem.id">
              <span v-if="subItem.creditBalance && parseFloat(subItem.creditBalance) != 0">{{ formatAmount(subItem.creditBalance) }}{{ subItem.currencyCode }}&nbsp;&nbsp;</span>
            </span>
            <span>临时额度：</span>
            <span v-for="subItem in accountItem.balances" :key="subItem.id">
              <span v-if="subItem.temporaryQuota && parseFloat(subItem.temporaryQuota) != 0">{{ formatAmount(subItem.temporaryQuota) }}{{ subItem.currencyCode }}&nbsp;&nbsp;</span>
            </span>
            <span>{{ accountItem.typeName }}：</span>
            <span v-for="subItem in accountItem.balances" :key="subItem.id">
              <span v-if="subItem.availableBalance && parseFloat(subItem.availableBalance) != 0">{{ formatAmount(subItem.availableBalance) }}{{ subItem.currencyCode }}&nbsp;&nbsp;</span>
            </span>
            <el-button type="text" @click="checkFlow(accountItem)">查看流水</el-button>
          </span>
          <span v-else>
            <!-- 只显示不为0的账户 -->
            <span v-if="accountItem.notZero">
              <span v-if="accountItem.balances.length > 0">
                {{ accountItem.name }} (
                <span style="color: blue">
                  {{ accountItem.userName }}
                </span>
                )
              </span>
              <span>固定额度：</span>
              <span v-for="subItem in accountItem.balances" :key="subItem.id">
                <span v-if="subItem.creditBalance && parseFloat(subItem.creditBalance) != 0">{{ formatAmount(subItem.creditBalance) }}{{ subItem.currencyCode }}&nbsp;&nbsp;</span>
              </span>
              <span>临时额度：</span>
              <span v-for="subItem in accountItem.balances" :key="subItem.id">
                <span v-if="subItem.temporaryQuota && parseFloat(subItem.temporaryQuota) != 0">{{ formatAmount(subItem.temporaryQuota) }}{{ subItem.currencyCode }}&nbsp;&nbsp;</span>
              </span>
              <span>{{ accountItem.typeName }}：</span>
              <span v-for="subItem in accountItem.balances" :key="subItem.id">
                <span v-if="subItem.availableBalance && parseFloat(subItem.availableBalance) != 0">{{ formatAmount(subItem.availableBalance) }}{{ subItem.currencyCode }}&nbsp;&nbsp;</span>
              </span>
              <el-button type="text" @click="checkFlow(accountItem)">查看流水</el-button>
            </span>
          </span>
        </div>
      </fieldset>
    </div>
  </div>
</template>

<script>
export default {
  name: "TradeCenterAccountTotal",

  data() {
    return {
      //查询条件
      queryInfo: {
        pageIndex: 1,
        pageSize: 36,
      },

      pageInfo: {
        total: 0,
      },
      ClassData: [],
      checked: false,
      cha: [], //资产-负债
      allZero: true, //资产-负债全是0
    };
  },
  created() {
    this.getData();
  },
  methods: {
    checkFlow(accountItem) {
      this.$router.push({
        path: "/home/centeraccountFlow",
        query: {
          typeName: accountItem.typeName,
          accountId: accountItem.accountId,
          name: accountItem.name,
          platform: this.$route.query.platform,
        },
      });
      // 本地存储
      localStorage.setItem("accountList", JSON.stringify(accountItem.balances));
    },
    query() {
      this.queryInfo.pageIndex = 1;
      this.getData();
    },
    async getData() {
      const { data: res } = await this.$http.get("/api/TradeCenterTotal/GetAccountBalanceGroupByType", {
        params: this.queryInfo,
      });
      if (res.code == -1) return this.$message.error(res.message);
      res.data.accountList.forEach((element) => {
        element.typeName = JSON.parse(element.totalTypeName);
        res.data.sum.forEach((v) => {
          v.typeName = JSON.parse(v.totalTypeName);
          if (element.totalTypeName === v.totalTypeName) {
            element.balancesSum = v.balancesSum;
          }
        });
        element.tradeCenterAccounts.forEach((v) => {
          v.balances.forEach((b) => {
            if (parseFloat(b.availableBalance) != 0) {
              v.notZero = "y";
            }
          });
        });
      });
      this.ClassData = res.data.accountList;

      //得到 资产、负债
      let AssetBalance = [];
      let LiabilityBalance = [];
      res.data.sum.forEach((v) => {
        if (v.totalTypeCode == "Asset") {
          AssetBalance = v.balancesSum;
        }
        if (v.totalTypeCode == "Liability") {
          LiabilityBalance = v.balancesSum;
        }
      });
      //计算 资产-负债
      let z_f = JSON.parse(JSON.stringify(AssetBalance));
      z_f.forEach((v) => {
        LiabilityBalance.forEach((m) => {
          if (v.currencyCode == m.currencyCode) {
            v.availableBalanceSum2 = m.availableBalanceSum;
          }
        });
      });
      LiabilityBalance.forEach((v) => {
        let exist = false;
        z_f.forEach((m) => {
          if (v.currencyCode == m.currencyCode) {
            exist = true;
          }
        });
        if (exist == false) {
          z_f.push({
            currencyCode: v.currencyCode,
            availableBalanceSum: "0",
            availableBalanceSum2: v.availableBalanceSum,
          });
        }
      });
      z_f.forEach((v) => {
        v.c = v.availableBalanceSum - v.availableBalanceSum2;
        if (v.c != 0) {
          this.allZero = false;
        }
      });
      this.cha = z_f;
    },
    //查询条件变化
    conditionChange() {
      this.queryInfo.pageIndex = 1;
    },
    // 监听 pageSize改变的事件
    handleSizeChange(newSize) {
      this.queryInfo.pageSize = newSize;
      this.getData();
    },
    // 监听 页码值 改变事件
    handleCurrentChange(newSize) {
      this.queryInfo.pageIndex = newSize;
      this.getData();
    },
    formatAmount(amount) {
      // 去除小数点前后多余的0
      amount = parseFloat(amount);

      if (isNaN(amount)) return "";
      var parts = String(amount).split(".");
      var integerPart = parts[0];
      var decimalPart = parts.length > 1 ? "." + parts[1] : "";

      // 每三位添加一个逗号
      integerPart = integerPart.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");

      return integerPart + decimalPart;
    },
  },
};
</script>

<style lang="less" scoped>
.search {
  display: flex;
  align-items: center;
  box-sizing: border-box;
  background-color: #fff;
  border-radius: 8px;
  padding: 10px 20px;
  > div {
    display: flex;
    align-items: center;
    > div {
      margin-right: 10px;
    }
  }
  :nth-child(2) {
    margin-left: 30px;
  }
}
.box {
  padding: 10px 0;
  .legend {
    padding: 0 5px;
  }
}
.accountBox {
  width: 100%;
  padding: 0 20px;
}
</style>
