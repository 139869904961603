<template>
  <div class="customerWalletAddress">
    <!-- 面包屑导航区 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>客户管理</el-breadcrumb-item>
      <el-breadcrumb-item>客户钱包地址</el-breadcrumb-item>
    </el-breadcrumb>
    <el-card class="box-card">
      <el-row>
        <el-col :span="8">
          <el-input placeholder="请输入内容" v-model="address" class="input-with-select">
            <el-button slot="append" @click="getCustomersAddresses" icon="el-icon-search"></el-button>
          </el-input>
        </el-col>
        <el-col :span="8">
          <el-button type="primary" @click="_batchDeletion">批量删除</el-button>
        </el-col>
      </el-row>
    </el-card>

    <el-table :data="customerData" header-align="center" ref="addressCustomer" style="width: 100%">
      <el-table-column type="selection" align="center" width="100"></el-table-column>
      <el-table-column label="账户编号" align="center" prop="bank_card_id" width="250"></el-table-column>
      <el-table-column label="账户类型" align="center" prop="bank_card_type_code" width="250"></el-table-column>
      <el-table-column label="币种" align="center" prop="bank_code" width="150"></el-table-column>
      <el-table-column label="客户" align="center" prop="name" width="260">
        <template slot-scope="scope">
          <span>{{ scope.row.name }}({{ scope.row.customer_id }})</span>
        </template>
      </el-table-column>
      <el-table-column label="地址" align="center" prop="address">
        <template slot-scope="scope">
          <span v-html="scope.row.address"></span>
        </template>
      </el-table-column>
      <el-table-column label="网络协议" align="center" prop="network" width="150"></el-table-column>
      <el-table-column label="是否固定" align="center" prop="is_fixed" width="150">
        <template slot-scope="scope">
          <el-switch @change="exchangeFixed(scope.row)" v-model="scope.row.is_fixed" active-color="#13ce66" inactive-color="#ff4949"> </el-switch>
        </template>
      </el-table-column>
      <el-table-column label="白名单" align="center" prop="is_white" width="150">
        <template slot-scope="scope">
          <el-switch @change="exchangeWhite(scope.row)" v-model="scope.row.is_white" active-color="#13ce66" inactive-color="#ff4949"> </el-switch>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>
<script>
export default {
  data() {
    return {
      address: "",
      customerData: [],
      formLabelAlign: {
        // 添加 formLabelAlign 对象
        no: "",
      },
    };
  },
  mounted() {
    if (this.$route.query.no) {
      this.address = this.$route.query.no;
      this.getCustomersAddresses();
    } else {
      this.formLabelAlign.no = "";
    }
  },
  methods: {
    async getCustomersAddresses() {
      const { data: res } = await this.$http.get("/api/CustomerBankCard/GetCustomerByVirtual", {
        params: {
          address: this.address,
        },
      });
      if (res.code !== 1) {
        this.$message.success(res.message);
      }
      let reg = new RegExp(this.address, "gi");
      res.data.forEach((item) => {
        item.address = item.qr_code ? item.qr_code.replace(reg, `<text style="font-weight: 600;color: red;">$&</text>`) : item.qr_code;
      });
      this.customerData = res.data;
    },
    // 修改是否固定
    async exchangeFixed(row) {
      const { data: res } = await this.$http.post("/api/CustomerBankCard/SetFixCustomerBankCard", {
        bank_card_id: row.bank_card_id,
        customer_id: row.customer_id,
        is_fixed: row.is_fixed,
      });
      if (res.code == 1) {
        this.$message.success("修改成功");
        this.getCustomersAddresses();
      } else {
        this.$message.error(res.message);
      }
    },

    // 修改是否白名单
    async exchangeWhite(row) {
      const { data: res } = await this.$http.post("/api/CustomerBankCard/SetWhiteCustomerBankCard", {
        bank_card_id: row.bank_card_id,
        customer_id: row.customer_id,
        is_white: row.is_white,
      });
      if (res.code == 1) {
        this.$message.success("修改成功");

        this.getCustomersAddresses();
      } else {
        this.$message.error(res.message);
      }
    },
    // 获取单选选中的数据
    getSelectedRows() {
      const selectedRows = this.$refs.addressCustomer.selection;
      return selectedRows;
    },
    // 删除客户
    async _batchDeletion() {
      try {
        // 确认对话框
        await this.$confirm("此操作将解除客户地址关联, 是否继续?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        });

        // 获取选中的行数据
        let selectedRows = this.getSelectedRows();
        if (selectedRows.length === 0) {
          this.$message.warning("请至少选择一条数据！");
          return;
        }

        // 构造删除数据
        let delateData = selectedRows.map((item) => ({
          bank_card_id: item.bank_card_id,
          customer_id: item.customer_id,
        }));

        // 调用接口删除
        const { data: res } = await this.$http.post("/api/CustomerBankCard/RemoveCustomerBankCardRelation", delateData);
        if (res.code == 1) {
          this.$message.success("删除成功");
          this.getCustomersAddresses(); // 刷新数据
        } else {
          this.$message.error(res.message);
        }
      } catch (error) {
        // 捕获取消操作或其他异常
        if (error === "cancel") {
          console.log("用户取消了操作");
        } else {
          this.$message.error("操作失败，请稍后重试！");
        }
      }
    },
  },
};
</script>
<style lang="less" scoped>
/deep/.el-checkbox__inner {
  width: 50px;
  /* 调整宽度 */
  height: 50px;
  /* 调整高度 */
}
</style>
